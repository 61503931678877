import {AiOutlineClose} from 'react-icons/ai';
import {TournamentTableBasicProps} from '../Types';
import LeaderboardSimpleLine from './LeaderboardSimpleLine';

type LeaderboardProps = TournamentTableBasicProps;

const LeaderboardSimple: React.FC<LeaderboardProps> = ({
  lines,
  title,
  text,
}) => {
  return (
    <div className="rounded-lg max-w-md py-5 text-[#E9E9E9] font-inter bg-[#2E2D2D]">
      <div className=" space-y-1.5 px-5 pb-5">
        <div className="relative flex justify-center items-center gap-2">
          <img src="/assets/icons/cup.png" className="w-6 drop-shadow-xl" />
          <span className="block font-bold text-xl font-play">
            {/* {title} */}
            Leaderboard
          </span>

          <div>
            <AiOutlineClose
              color="#92939C"
              className="w-4 h-4 absolute right-0 top-0 cursor-pointer"
            />
          </div>
        </div>
        <div className="text-[#92939C] text-sm text-center font-semibold">
          10.12.2022 - 10.12.2022
        </div>

        <div className="pt-4 text-sm text-center">
          {/* {text} */}
          Lorem ipsum dolor sit amet consectetur. Elementum suspendisse a ut
          ultrices habitasse egestas tellus. Nascetur.
        </div>
      </div>

      <div className="font-medium mt-2">
        <table className="w-full table-auto">
          <tbody>
            {lines.slice(0, 6).map((x, index) => (
              <LeaderboardSimpleLine key={index} {...x} />
            ))}
            <tr className="even:bg-[#464646]/20 text-sm">
              <td className="font-semibold py-3 px-5" colSpan={3}>
                ...
              </td>
            </tr>
            <LeaderboardSimpleLine
              place={21852}
              name={'0xbF4...BD23'}
              customProperties={[{value: '50.2'}]}
              isHighlightet
            />
            <tr className="even:bg-[#464646]/20 text-sm">
              <td className="font-semibold py-3 px-5" colSpan={3}>
                ...
              </td>
            </tr>
            <LeaderboardSimpleLine
              place={32124}
              name={'0xbF4...BD23'}
              customProperties={[{value: '0.95'}]}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderboardSimple;
