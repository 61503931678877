import {Canvas} from '@react-three/fiber';
import {useRef, useState} from 'react';
import React from 'react';
import FootballGate from '../../../components/game/mechanics/football/FootballGate';
import FootballCharacter, {
  FootballCharacterPropsRefProps,
} from './FootballCharacter';
import {Euler, Vector3} from 'three';
import {ConstructorElement} from '../../../components/game/scenes/ConstructorScene';
import {HeadModel} from '../../../types/models/HeadModel';
import {BodyModel} from '../../../types/models/BodyModel';
import {useGLTF} from '@react-three/drei';

export interface GateQuestion {
  question: string;
  sectors: GateSector[];
}

export interface GateSector {
  id: string;
  title: string;
  isCorrect: boolean;
}

const FootballMinigameScene: React.FC = () => {
  const playerRef =
    useRef<FootballCharacterPropsRefProps>() as React.MutableRefObject<FootballCharacterPropsRefProps>;

  const [quizSectors] = useState<GateQuestion[]>([
    {
      question: 'How many people watched the 2018 World Cup worldwide?',
      sectors: [
        {
          id: '105ACCFD-1380-47B2-AD0D-674A77DB4997',
          title: '3.5 billion',
          isCorrect: true,
        },
        {
          id: '62FB547A-1476-4C31-A8E5-2EC34E42B5B5',
          title: '1.2 billion',
          isCorrect: false,
        },
        {
          id: 'F75D8CEC-B730-483F-A942-6FFF512A53A3',
          title: '6.7 billion',
          isCorrect: false,
        },
        {
          id: '82169E35-F110-4741-B75E-9F157EAE46D6',
          title: '2.6 billion',
          isCorrect: false,
        },
      ],
    },
    {
      question: 'How many matches will be played at the 2022 World Cup?',
      sectors: [
        {
          id: '31DD0001-CA96-4735-911D-C3CF7E2A89CD',
          title: '128',
          isCorrect: false,
        },
        {
          id: '383B16D1-86B1-46C9-82AD-2D2B4FF0B460',
          title: '64',
          isCorrect: true,
        },
        {
          id: '54453BFE-529D-4D08-A6FA-2FAEB4D0C74B',
          title: '32',
          isCorrect: false,
        },
        {
          id: 'A89D492B-B41B-4CD0-A301-87DA0907CB8F',
          title: '63',
          isCorrect: false,
        },
      ],
    },
    {
      question: 'Which of these is not a football player?',
      sectors: [
        {
          id: '39600C40-FE4C-4CED-AF3F-30098B111E53',
          title: 'Lionel Messi',
          isCorrect: false,
        },
        {
          id: 'ABEF20A3-1556-4B22-85D2-94F5C8258B0F',
          title: 'Mohamed Salah',
          isCorrect: false,
        },
        {
          id: 'E4F9EFD0-5039-4E7C-926C-3431D030F39A',
          title: 'Michael Phelps',
          isCorrect: true,
        },
        {
          id: 'BD340CBB-EDC0-4180-8CFA-9B00CD4E96E0',
          title: 'Cristiano Ronaldo',
          isCorrect: false,
        },
      ],
    },
  ]);

  const [currentQuizSlide, setCurrentQuizSlide] = useState(0);

  const [headModel, setHeadModel] = useState<
    ConstructorElement<HeadModel> | undefined
  >({
    model: useGLTF(
      'https://storage.googleapis.com/stemmy-integrations/models/character/AlienHead.glb',
    ) as unknown as HeadModel,
    address:
      'https://storage.googleapis.com/stemmy-integrations/models/character/AlienHead.glb',
  });
  const [bodyModel, setBodyModel] = useState<
    ConstructorElement<BodyModel> | undefined
  >({
    model: useGLTF(
      'https://storage.googleapis.com/stemmy-integrations/models/character/NinjaBody.glb',
    ) as unknown as BodyModel,
    address:
      'https://storage.googleapis.com/stemmy-integrations/models/character/NinjaBody.glb',
  });

  return (
    <div className="w-screen h-screen aspect-video">
      <div
        style={{width: '40%'}}
        className="absolute right-0 h-full aspect-video"
      >
        <Canvas
          className="z-20 w-1/3"
          shadows
          camera={{rotation: [0, 0, 0], position: [0, 4, 5], fov: 40}}
        >
          <ambientLight color="#fff" intensity={0.3} />
          <pointLight color="#fff" position={[0, 5, 0]} />
          <directionalLight
            position={[0, 15, 10]}
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
          />

          <FootballCharacter
            ref={playerRef}
            position={new Vector3(0.6, 0, 0)}
            rotation={new Euler(0, 0, 0)}
            // headModel={headModel?.model}
            // bodyModel={bodyModel?.model}
          />
          {/* <Chest ref={chestRef} isAutoOpen={false}>
          <mesh
            name="SWORD1"
            geometry={nodes.SWORD1.geometry}
            material={materials.BlueWeapon}
            rotation={[83, Math.PI, Math.PI]}
            scale={1.2}
            position={[0, 1.6, 0]}
          />
        </Chest>

        <mesh position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} receiveShadow>
          <boxBufferGeometry attach="geometry" />
          <shadowMaterial
            attach="material"
            color="#000"
            opacity={0.2}
            transparent
          />
        </mesh> */}
        </Canvas>
      </div>
      <div className="absolute top-0 w-4/5 z-0">
        <FootballGate
          question={quizSectors[currentQuizSlide]}
          onAnswered={(isCorrect) => {
            if (isCorrect) {
              playerRef.current.kick();
            } else {
              playerRef.current.powerKick();
            }

            setTimeout(() => {
              setCurrentQuizSlide((x) => x + 1);
            }, 3000);
          }}
        />
      </div>
      {/* <div className="flex gap-2">
        <Button
          onClick={() => {
            playerRef.current.kick();
          }}
        >
          Kick
        </Button>
        <Button
          onClick={() => {
            playerRef.current.powerKick();
          }}
        >
          Power kick
        </Button>
      </div> */}
    </div>
  );
};

export default FootballMinigameScene;
