import classNames from 'classnames';
import React, {useState} from 'react';
import {ButtonSize} from '../../buttons/yellowShort/StemmyYellowButton';
import './Button.scoped.css';

type ButtonProps = {
  btnSize?: ButtonSize;
  noRadius?: boolean;
  disableHover?: boolean;
  buttonStyle?: ButtonStyleDef;
  isSelected?: boolean;
  fontFamily?: string;
  textStyles?: string;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonStyleDef {
  button: string;
  buttonUnder?: string;
  hover: string;
  buttonUpperPanel: string;
  selected: string;
}

interface ButtonStyleObject {
  [name: string]: ButtonStyleDef;
}

// button - это стили к основной видимой части кнопки, buttonUnder - это маленькая часть снизу, buttonUpperPanel - светлая часть сверху
export const ButtonStyle: ButtonStyleObject = {
  Yellow: {
    button: 'bg-stemmy-yellow text-white',
    buttonUnder: 'bg-[#DAB505]',
    hover: 'hover:bg-[#bfa005]',
    buttonUpperPanel: 'bg-[#FFE151]',
    selected: '',
  },
  Purple: {
    button: 'bg-stemmy-purple text-white',
    buttonUnder: 'bg-[#8123F4]',
    hover: 'hover:bg-[#4E1C97]',
    buttonUpperPanel: 'bg-[#914EF1]',
    selected: 'bg-[#2d0568] hover:bg-[#2d0568]',
  },
  Green: {
    button: 'bg-stemmy-green text-white',
    buttonUnder: 'bg-[#71D91A]',
    hover: 'hover:bg-[#00d830]',
    buttonUpperPanel: 'bg-[#86F22B]',
    selected: '',
  },
  Red: {
    button: 'bg-stemmy-red text-white',
    buttonUnder: 'bg-[#E91416]',
    hover: 'hover:bg-[#b31d1d]',
    buttonUpperPanel: 'bg-[#FF4344]',
    selected: '',
  },
  Blue: {
    button: 'bg-stemmy-blue text-white',
    buttonUnder: 'bg-[#2544E2]',
    hover: 'hover:bg-[#243cb4]',
    buttonUpperPanel: 'bg-[#3354F2]',
    selected: '',
  },
};

// className применяем к трем разным блокам(button и двум div из которых состоит вся кнопка). Так получилось, что у нас кнопка состоит из трех частей: 1) подложка, 2) над ней наибольшая видимая часть всей кнопки и немного поднятая, чтобы от подложки осталась только внизу полоска, 3) от середины вверх идет светлая часть. И когда рендерят с кастомным стилем <Button className="123">, стиль 123 применяется сразу к трем этим частям кнопки. Или можно на выбор. Раньше он применялся только к одному блоку <button> (82 строка), потому что кнопка состояла из двух частей, этого button и светлой верхней части, которая была на 80% прозрачна и можно было применить стиль только к button. Но теперь если бы мы продолжали применять кастомные классы только к одной части кнопки, в итоге остальные не будут менять стиль и выглядит в итоге не очень.
const Button: React.FC<ButtonProps> = ({
  className,
  type,
  children,
  btnSize,
  onClick,
  buttonStyle = ButtonStyle.Yellow,
  disableHover = false,
  noRadius = false,
  disabled = false,
  isSelected = false,
  fontFamily = 'font-jost',
  textStyles,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      type={type}
      disabled={disabled}
      className={classNames(
        btnSize,
        !noRadius && 'rounded-md',
        !disableHover && !disabled && buttonStyle.hover,
        buttonStyle.buttonUnder ? buttonStyle.buttonUnder : buttonStyle.button,
        isSelected && buttonStyle.selected,
        'px-5 lg:px-10 py-1 text-white font-bold text-center cursor-pointer flex justify-center items-center relative overflow-hidden',
        disabled && 'grayscale',
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      <div
        className={classNames(
          className,
          buttonStyle.buttonUpperPanel,
          isHovered && 'opacity-50',
          'absolute w-full h-full -top-1/2 rounded-[48.8702px] z-20 pointer-events-none',
        )}
      />
      <div
        className={classNames(
          className,
          buttonStyle.button,
          !disableHover && !disabled && buttonStyle.hover,
          'absolute w-full h-full -top-[6%] rounded-[10px] z-10',
        )}
      />
      <span
        className={classNames(
          'z-30 pointer-events-none',
          fontFamily,
          textStyles,
        )}
      >
        {children}
      </span>
    </button>
  );
};
export default Button;
