import {useRef, useState} from 'react';
import {OnboardingJetpackRef} from '../../../components/animatedCharacters/onboardingJetpack/Contract';
import SuitGirlJetpack from '../../../components/animatedCharacters/onboardingJetpack/SuitGirlJetpack';
import JetpackScriptIntegration from '../../../components/scriptIntegrations/JetpackIntegration';

const JetpackGirlWrapper: React.FC = () => {
  const characterRef = useRef<OnboardingJetpackRef>(null);

  const [jetpackLoaded, setJetpackLoaded] = useState<boolean>(false);

  return (
    <JetpackScriptIntegration
      characterRef={characterRef}
      isAnimationLoaded={jetpackLoaded}
    >
      <SuitGirlJetpack
        onLoaded={() => {
          setJetpackLoaded(true);
        }}
        ref={characterRef}
      />
    </JetpackScriptIntegration>
  );
};

export default JetpackGirlWrapper;
