import {useLoader, useThree} from '@react-three/fiber';
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as THREE from 'three';

interface GameSoundProps {
  src: string;
  volume?: number;
  autoplay?: boolean;
  loop?: boolean;
}

export interface GameSoundRefProps {
  play: () => void;
  stop: () => void;
}

const GameSound = forwardRef<GameSoundRefProps, GameSoundProps>(
  ({src, volume = 1, autoplay = false, loop = false}, ref) => {
    const sound = useRef<THREE.PositionalAudio>(null);
    const buffer = useLoader(THREE.AudioLoader, src);
    const {camera} = useThree();
    const [listener] = useState(() => new THREE.AudioListener());

    useEffect(() => {
      const crntSound = sound.current;
      return () => {
        if (crntSound && crntSound.isPlaying) crntSound?.stop();
      };
    }, [sound]);

    useImperativeHandle(ref, () => ({
      play: () => {
        sound.current?.play();
      },
      stop: () => {
        if (sound.current && sound.current.isPlaying) sound.current.stop();
      },
    }));

    useEffect(() => {
      if (!sound.current || !buffer) return;
      sound.current.setBuffer(buffer);
      sound.current.setRefDistance(1);
      sound.current.setVolume(volume);

      camera.add(listener);
    }, [buffer, camera, listener, volume]);

    return (
      <positionalAudio
        ref={sound}
        args={[listener]}
        autoplay={autoplay}
        loop={loop}
      />
    );
  },
);

export default GameSound;
