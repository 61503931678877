import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import {GirlJetpackCharacterBase} from '../../../types/models/v2/bases/GirlJetpackCharacterBase';
import {DefaultModel} from '../types/basic';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GirlJetpackCharacterProps extends DefaultModel {}

const GirlJetpackCharacter: React.FC<GirlJetpackCharacterProps> = ({
  onLoaded,
  position,
  rotation,
  scale,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(
    '/models/v2/fullModels/CoffeJetPackGirl.glb',
  ) as unknown as GirlJetpackCharacterBase;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
      receiveShadow
      castShadow
    >
      <group name="Scene">
        <group name="Armature">
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh
            name="CoffeeGirl"
            geometry={nodes.CoffeeGirl.geometry}
            material={materials.Ch03_Body}
            skeleton={nodes.CoffeeGirl.skeleton}
          />
          {/* <skinnedMesh
            name="JetPack"
            geometry={nodes.JetPack.geometry}
            material={materials.jet}
            skeleton={nodes.JetPack.skeleton}
          /> */}
        </group>
      </group>
    </group>
  );
};

export default GirlJetpackCharacter;
