import {UseQueryResult, useQuery, useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  UseShortMutationOptions,
  UseShortQueryOptions,
} from '../../../helpers/ReactQuesyOptionBuilder';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {DialogDTO} from './dto/DialogDTO';
import {GetDialogByIdRequest} from './request/GetDialogRequest';

export const GetDialogKey = 'integration/tournament/get';

export function useGetDialog(
  request: GetDialogByIdRequest,
  options?: UseShortQueryOptions<DialogDTO, AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
): UseQueryResult<DialogDTO, AxiosError> {
  return useQuery(
    [GetDialogKey, request.dialogId],
    async (): Promise<DialogDTO> => {
      const {data} = await APIRequest.post<DialogDTO>(
        '/Integration/Dialog/GetById',
        request,
      );
      return data;
    },
    {
      onError: errorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const useGetDialogOnRequest = (
  options?: UseShortMutationOptions<
    DialogDTO,
    AxiosError<ApiError>,
    GetDialogByIdRequest
  >,
) => {
  return useMutation<DialogDTO, AxiosError<ApiError>, GetDialogByIdRequest>(
    [GetDialogKey],
    async (request: GetDialogByIdRequest): Promise<DialogDTO> => {
      const {data} = await APIRequest.post<DialogDTO>(
        '/Integration/Dialog/GetById',
        request,
      );
      return data;
    },
    {
      ...options,
    },
  );
};
