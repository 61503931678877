import {Canvas} from '@react-three/fiber';
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import React from 'react';
import EventsMessanger, {
  BaseMessagingEvent,
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';
import {OnboardingJetpackRef} from '../animatedCharacters/onboardingJetpack/Contract';
import {BasicElement} from '../../helpers/BasicElement';

interface JetpackGettingEvent {
  event: 'idle' | 'takeoff' | 'land' | 'dance' | 'wave' | 'checkLoaded';
}

interface JetpackPointerEvent {
  event: 'point';
  horizontally: 'left' | 'right';
  vertically: 'up' | 'middle' | 'down';
  playOnce?: boolean;
}

interface JetPackSendingEvent extends BaseMessagingEvent {
  event: 'onClick' | 'onLoaded';
}

export interface JetpackScriptIntegrationProperties extends BasicElement {
  characterRef: React.RefObject<OnboardingJetpackRef>;
  isAnimationLoaded: boolean;
}

export interface JetpackScriptIntegrationRef {
  // onAnimationLoaded: () => void;
}

const JetpackScriptIntegration = forwardRef<
  JetpackScriptIntegrationRef,
  JetpackScriptIntegrationProperties
>(({characterRef, isAnimationLoaded, children}, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const eventMessangerRef =
    useRef<EventSystemRefProps<JetPackSendingEvent>>(null);

  const incomingEventsHandler = useCallback(
    (message: JetpackGettingEvent | JetpackPointerEvent) => {
      if (message.event === 'point') {
        const converted = message as JetpackPointerEvent;

        switch (converted.vertically) {
          case 'down':
            if (converted.horizontally === 'left')
              characterRef.current?.pointDownLeft(converted.playOnce || false);
            else
              characterRef.current?.pointDownRight(converted.playOnce || false);
            break;
          case 'up':
            if (converted.horizontally === 'left')
              characterRef.current?.pointUpLeft(converted.playOnce || false);
            else
              characterRef.current?.pointUpRight(converted.playOnce || false);
            break;

          case 'middle':
          default:
            if (converted.horizontally === 'left')
              characterRef.current?.pointLeft(converted.playOnce || false);
            else characterRef.current?.pointRight(converted.playOnce || false);
            break;
        }

        return;
      }

      const converted = message as JetpackGettingEvent;
      switch (converted.event) {
        case 'dance':
          characterRef.current?.dance();
          break;

        case 'land':
          characterRef.current?.land();
          break;

        case 'takeoff':
          characterRef.current?.takeoff();
          break;

        case 'wave':
          characterRef.current?.wave();
          break;

        case 'checkLoaded':
          if (isLoaded) {
            eventMessangerRef.current?.sendMessage(
              {
                event: 'onLoaded',
              },
              'jetpack',
            );
          }
          break;

        default:
        case 'idle':
          characterRef.current?.idle();
          break;
      }
    },
    [characterRef, isLoaded],
  );

  const [, setComponentsReady] = useState({
    animator: false,
    messanger: false,
    master: false,
  });

  const onLoaded = useCallback(
    (source: 'animator' | 'messanger' | 'master') => {
      setComponentsReady((old) => {
        const updated = {...old, [source]: true};

        if (updated.animator && updated.master && updated.messanger) {
          setIsLoaded(true);
          eventMessangerRef.current?.sendMessage(
            {
              event: 'onLoaded',
            },
            'jetpack',
          );
        }
        return updated;
      });
    },
    [],
  );

  useEffect(() => {
    if (isAnimationLoaded) onLoaded('animator');
  }, [isAnimationLoaded, onLoaded]);

  // useImperativeHandle(ref, () => ({
  //   onAnimationLoaded() {
  //     onLoaded('animator');
  //   },
  // }));

  useEffect(() => {
    onLoaded('master');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="w-screen h-screen relative"
      onClick={() => {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onClick',
          },
          'jetpack',
        );
      }}
    >
      <EventsMessanger<
        JetpackGettingEvent | JetpackPointerEvent,
        JetPackSendingEvent
      >
        componentName="jetpack"
        myRef={eventMessangerRef}
        onMessage={incomingEventsHandler}
        onLoaded={() => onLoaded('messanger')}
      />
      <Canvas
        shadows
        camera={{rotation: [0, 0, 0], position: [0, 1.15, 5], fov: 28}}
      >
        <ambientLight color="#fff" intensity={0.3} />
        <pointLight color="#fff" position={[0, 5, 5]} />
        {children}
      </Canvas>
      {/* <div className="absolute top-5 left-5">
        <Button
          onClick={() => {
            incomingEventsHandler({
              event: 'point',
              horizontally: 'left',
              vertically: 'up',
            });
          }}
        >
          Click
        </Button>
      </div> */}
    </div>
  );
});

export default JetpackScriptIntegration;
