import mixpanel from 'mixpanel-browser';
import StatisticsEventHandler from '../../../components/scriptIntegrations/StatisticsEventHandler';

const StatisticsWrapper: React.FC = () => {
  return (
    <StatisticsEventHandler
      onAuthoriseRequest={(data) => {
        console.log(`Authorising ${data}`);
        mixpanel.identify(data);
      }}
    />
  );
};

export default StatisticsWrapper;
