import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortQueryOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {
  TournamentAnonymusReqeust,
  TournamentReqeust,
} from './dao/TournamentRecordDAO';
import {TournamentAnonymusDTO} from './dto/TournamentDTO';

export const GetTournamentKey = 'integration/tournament/get';
export const GetUserTournamentKey = 'integration/tournament/userget';

export function useGetUserTournament(
  request: TournamentReqeust,
  options?: UseShortQueryOptions<TournamentAnonymusDTO[], AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
): UseQueryResult<TournamentAnonymusDTO[], AxiosError> {
  return useQuery(
    [GetUserTournamentKey, request.tournamentId],
    async (): Promise<TournamentAnonymusDTO[]> => {
      const {data} = await APIRequest.post<TournamentAnonymusDTO[]>(
        '/Integration/GetUserTournamentData',
        request,
      );
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetTournament(
  request: TournamentAnonymusReqeust,
  options?: UseShortQueryOptions<TournamentAnonymusDTO[], AxiosError<ApiError>>,
  errorHandler?: (error: AxiosError<ApiError>) => void,
): UseQueryResult<TournamentAnonymusDTO[], AxiosError> {
  return useQuery(
    [GetTournamentKey, request.tournamentId],
    async (): Promise<TournamentAnonymusDTO[]> => {
      const {data} = await APIRequest.post<TournamentAnonymusDTO[]>(
        '/Integration/GetTournamentData',
        request,
      );
      return data;
    },
    {
      onError: errorHandler || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}
