import {AxiosInstance} from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {UseDialogMocks} from './Integration/dialog/mock/DialogMock';
import {UseTournamentMock} from './Integration/tournament/mock/TournamentMock';
import {UsePingMocks} from './ping/mock/PingMock.dev';

export default function UseMocks(axiosInstance: AxiosInstance) {
  const mock = new MockAdapter(axiosInstance, {delayResponse: 0});

  UsePingMocks(mock);

  mock.onAny().passThrough();
}

export function UseProdMocks(axiosInstance: AxiosInstance) {
  const mock = new MockAdapter(axiosInstance, {delayResponse: 0});

  UseDialogMocks(mock);
  UseTournamentMock(mock);

  mock.onAny().passThrough();
}
