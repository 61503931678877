import React from 'react';
import { Notification as ReactNotification } from 'src/components/Notification';
import store from 'src/store';
import { DEFAULT_CONTAINER_VALUES as DCV } from 'src/utils/constants';
import { getNotificationsForEachContainer, getNotificationsForMobileView, isNullOrUndefined } from 'src/utils/helpers';
import "src/scss/notification.scss";
export { Container };
class Container extends React.Component {
    constructor(props) {
        super(props);
        this.handleResize = () => {
            this.setState({ windowWidth: window.innerWidth });
        };
        this.add = (notification) => {
            this.setState(({ notifications }) => {
                const nextNotifications = [...notifications];
                const i = nextNotifications.findIndex(({ id }) => id === notification.id);
                if (i > -1) {
                    nextNotifications[i] = notification;
                    return {
                        notifications: nextNotifications
                    };
                }
                return {
                    notifications: notification.insert === 'top'
                        ? [notification, ...nextNotifications]
                        : [...nextNotifications, notification]
                };
            });
            return notification.id;
        };
        this.remove = (id) => {
            this.setState(({ notifications }) => ({
                notifications: notifications.map((notification) => {
                    if (notification.id === id) {
                        notification.hasBeenRemoved = true;
                    }
                    return notification;
                })
            }));
        };
        this.removeAllNotifications = () => {
            this.setState({
                notifications: this.state.notifications.map((notification) => (Object.assign(Object.assign({}, notification), { hasBeenRemoved: true })))
            });
        };
        this.toggleRemoval = (id, callback) => {
            this.setState(({ notifications }) => ({
                notifications: notifications.filter(({ id: nId }) => nId !== id)
            }), callback);
        };
        this.state = {
            isMobile: isNullOrUndefined(props.isMobile) ? DCV.isMobile : props.isMobile,
            breakpoint: isNullOrUndefined(props.breakpoint) ? DCV.breakpoint : props.breakpoint,
            notifications: [],
            windowWidth: undefined
        };
    }
    componentDidMount() {
        const { types, defaultNotificationWidth } = this.props;
        store.register({
            addNotification: this.add,
            removeNotification: this.remove,
            removeAllNotifications: this.removeAllNotifications,
            defaultNotificationWidth: defaultNotificationWidth || DCV.defaultNotificationWidth,
            types
        });
        this.setState({ windowWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    renderNotifications(notifications, isMobile) {
        return notifications.map((notification) => (React.createElement(ReactNotification, { id: notification.id, key: notification.id, isMobile: isMobile, defaultNotificationWidth: this.props.defaultNotificationWidth, notification: notification, toggleRemoval: this.toggleRemoval, notificationsCount: notifications.length, hasBeenRemoved: notification.hasBeenRemoved })));
    }
    renderMobileNotifications(props) {
        const { className, id } = props;
        const { notifications } = this.state;
        const mobileNotifications = getNotificationsForMobileView(notifications);
        const top = this.renderNotifications(mobileNotifications.top, true);
        const bottom = this.renderNotifications(mobileNotifications.bottom, true);
        return (React.createElement("div", { id: id, key: "mobile", className: `rnc__base ${className || ''}` },
            React.createElement("div", { className: "rnc__notification-container--mobile-top" }, top),
            React.createElement("div", { className: "rnc__notification-container--mobile-bottom" }, bottom)));
    }
    renderScreenNotifications(props) {
        const { className, id } = props;
        const { notifications } = this.state;
        const items = getNotificationsForEachContainer(notifications);
        const topFull = this.renderNotifications(items.topFull, false);
        const bottomFull = this.renderNotifications(items.bottomFull, false);
        const topLeft = this.renderNotifications(items.topLeft, false);
        const topRight = this.renderNotifications(items.topRight, false);
        const topCenter = this.renderNotifications(items.topCenter, false);
        const bottomLeft = this.renderNotifications(items.bottomLeft, false);
        const bottomRight = this.renderNotifications(items.bottomRight, false);
        const bottomCenter = this.renderNotifications(items.bottomCenter, false);
        const center = this.renderNotifications(items.center, false);
        return (React.createElement("div", { id: id, key: "screen", className: `rnc__base ${className || ''}` },
            React.createElement("div", { className: "rnc__notification-container--top-full" }, topFull),
            React.createElement("div", { className: "rnc__notification-container--bottom-full" }, bottomFull),
            React.createElement("div", { className: "rnc__notification-container--top-left" }, topLeft),
            React.createElement("div", { className: "rnc__notification-container--top-right" }, topRight),
            React.createElement("div", { className: "rnc__notification-container--bottom-left" }, bottomLeft),
            React.createElement("div", { className: "rnc__notification-container--bottom-right" }, bottomRight),
            React.createElement("div", { className: "rnc__notification-container--top-center" }, topCenter),
            React.createElement("div", { className: "rnc__notification-container--center" },
                React.createElement("div", { className: "rnc__util--flex-center" }, center)),
            React.createElement("div", { className: "rnc__notification-container--bottom-center" }, bottomCenter)));
    }
    render() {
        const { isMobile } = this.props;
        const { windowWidth, breakpoint } = this.state;
        if (isMobile && windowWidth <= breakpoint) {
            return this.renderMobileNotifications(this.props);
        }
        return this.renderScreenNotifications(this.props);
    }
}
