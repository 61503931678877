import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {GateQuestion} from '../../../../pages/scriptIntegrations/football/Football';
import FootballSector from './FootballSector';

interface FootballGateProps {
  question: GateQuestion;

  onAnswered: (isCorrect: boolean) => void;
}

const FootballGate: React.FC<FootballGateProps> = ({question, onAnswered}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isShowAnswer, setIsShowAnswer] = useState(false);

  useEffect(() => {
    console.log('Used effect');
    setIsSelected(false);
    setIsShowAnswer(false);
  }, [question]);

  return (
    <div>
      <div className="w-full text-game-xl text-center mb-2 sm:mb-4">
        {question.question}
      </div>
      <div
        className={classNames(
          'grid grid-cols-2 h-full gap-2 text-game-lg bg-cover bg-no-repeat',
        )}
        style={{
          aspectRatio: '535 / 202',
          backgroundImage: 'url("/football/gate.png")',
          padding: '4%',
        }}
      >
        {question.sectors.map((element, index) => (
          <FootballSector
            questionAnswer={element}
            x={index}
            y={0}
            onSelected={(id, x, y, isCorrect) => {
              setIsSelected(true);

              onAnswered(isCorrect);

              setTimeout(() => {
                setIsShowAnswer(true);
              }, 1300);
            }}
            isShowAnswer={isShowAnswer}
            isProhibitClick={isSelected}
          />
        ))}
      </div>
    </div>
  );
};
export default FootballGate;
