import classNames from 'classnames';
import {motion} from 'framer-motion';
import {useEffect} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {SliderAnimationVariants} from '../../../styles/framerMotionVariants';
import {DialogElement, DialogWindowSize} from '../types';
import ButtonsListGame from './ButtonsList';

interface DialogPopupElementProps {
  prefixId: string;
  element: DialogElement;
  windowSize?: DialogWindowSize;

  onClick: (id: string) => void;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
  onFallback: () => void;
  onJumpToSlide: (val: number) => void;

  onWidthChanged?: (width: number) => void;
  onHeightChanged?: (heigth: number) => void;
}

const DialogPopupElementSimple: React.FC<DialogPopupElementProps> = ({
  element: {name, text, buttons, testButtons, character},
  prefixId,
  windowSize,
  onClick,
  onBack,
  onNext,
  onFinish,
  onFallback,
  onJumpToSlide,

  onWidthChanged,
  onHeightChanged,
}) => {
  const {width, height, ref} = useResizeDetector({
    refreshRate: 200,
  });

  useEffect(() => {
    if (!height || !onHeightChanged) return;
    onHeightChanged(height);
  }, [height, onHeightChanged]);

  useEffect(() => {
    if (!width || !onWidthChanged) return;
    onWidthChanged(width);
  }, [onWidthChanged, width]);

  return (
    <div
      ref={ref}
      className={classNames(
        'absolute',
        windowSize === DialogWindowSize.Fullscreen
          ? 'w-full'
          : 'w-fit max-w-md',
      )}
    >
      <div
        className={classNames(
          'p-4 relative font-play',
          character ? 'my-8' : 'mt-2 mb-6',
        )}
      >
        <motion.div
          initial={'hidden'}
          animate={'visible'}
          transition={{duration: 0.25}}
          variants={SliderAnimationVariants}
          className={classNames(
            'relative rounded-lg bg-[#262626] text-white cursor-default h-fit p-5 text-center',
            'flex flex-col gap-2',
            character && 'ml-16',
          )}
        >
          {name && <div className="font-bold text-xl">{name}</div>}

          <p className="text-sm font-inter text-[#E9E9E9]">{text}</p>

          {buttons && (
            <div className="mt-4 justify-center flex flex-row-reverse gap-3.5 z-20">
              <ButtonsListGame
                prefixId={prefixId}
                buttons={buttons}
                onClick={onClick}
                onFinish={onFinish}
                onFallback={onFallback}
                onBack={onBack}
                onNext={onNext}
                onJumpToSlide={onJumpToSlide}
              />
            </div>
          )}

          {testButtons && (
            <div className="mt-4 grid grid-cols-2 mt-1 gap-3.5 z-20">
              <ButtonsListGame
                prefixId={prefixId}
                buttons={testButtons}
                onClick={onClick}
                onFinish={onFinish}
                onFallback={onFallback}
                onBack={onBack}
                onNext={onNext}
                onJumpToSlide={onJumpToSlide}
              />
            </div>
          )}
        </motion.div>

        {character && (
          <div
            className={classNames('absolute bottom-[-30px] ', 'left-[-10px]')}
          >
            <img src={character.image} className="w-36" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogPopupElementSimple;
