import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import {useState} from 'react';
import SimpleButton, {
  ButtonStyleSimple,
} from '../../../form/button/SimpleButton';
import {PopupButton} from '../../button/types';

interface ButtonsListProps {
  prefixId: string;
  buttons: PopupButton[];

  onClick: (buttonId: string) => void;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
  onFallback: () => void;
  onJumpToSlide: (val: number) => void;
}

const ButtonsListSimple: React.FC<ButtonsListProps> = ({
  prefixId,
  buttons,
  onClick,
  onBack,
  onNext,
  onFinish,
  onFallback,
  onJumpToSlide,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  return (
    <>
      {buttons.map((x, index) => (
        <SimpleButton
          key={index}
          disableHover={selectedAnswer !== undefined}
          buttonStyle={x.dexButtonStyle ?? ButtonStyleSimple.Common}
          className={classNames(
            x.className,
            buttons.length === 1 && 'w-7/12',
            selectedAnswer === index &&
              (x.isCoorrectAnswer === true
                ? 'correct-answer-shadow'
                : 'wrong-answer-shadow'),
            selectedAnswer !== undefined &&
              (x.isCoorrectAnswer === true
                ? 'bg-[#67CA9D] !text-white'
                : 'bg-[#F27870] !text-white'),
            x.isFullLine && 'col-span-2',
          )}
          disabled={!!selectedAnswer}
          onClick={() => {
            if (x.isCoorrectAnswer !== undefined) {
              setSelectedAnswer(index);
            }
            // console.log(
            //   `STATISTICS: Button press event: "${prefixId}.${x.id}"`,
            // );
            mixpanel.track('Game button press', {
              prefix: prefixId,
              id: x.id,
            });

            setTimeout(() => {
              onClick(x.id);
              if (x.isBackOnClick) onBack();
              if (x.isNextOnClick) onNext();
              if (x.isFinishOnClick) onFinish();
              if (x.isFallbackOnClick) onFallback();
              if (x.jumpToIndexOnClick !== undefined) {
                onJumpToSlide(x.jumpToIndexOnClick);
              }
              if (x.onClick) {
                x.onClick(x.id);
              }
            }, x.clickDelay || 0);
          }}
        >
          {x.title}
        </SimpleButton>
      ))}
    </>
  );
};

export default ButtonsListSimple;
