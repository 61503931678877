import {Canvas} from '@react-three/fiber';
import {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import React from 'react';
import {StaticSword} from '../../../types/models/StaticSword';
import Chest, {ChestRefProps} from '../../../components/game/models/Chest';

const ScriptChestScene: React.FC = () => {
  const chestRef =
    useRef<ChestRefProps>() as React.MutableRefObject<ChestRefProps>;

  const {nodes, materials} = useGLTF(
    '/models/others/staticweapons/sword.glb',
  ) as unknown as StaticSword;

  return (
    <div className="w-screen h-screen">
      <Canvas
        shadows
        camera={{rotation: [-0.3, 0, 0], position: [0, 2.8, 5], fov: 30}}
      >
        <ambientLight color="#fff" intensity={0.3} />
        <pointLight color="#fff" position={[0, 5, 0]} />
        <directionalLight
          position={[0, 15, 10]}
          intensity={0.5}
          castShadow
          shadow-mapSize-height={512}
          shadow-mapSize-width={512}
        />

        <Chest ref={chestRef} isAutoOpen={false}>
          <mesh
            name="SWORD1"
            geometry={nodes.SWORD1.geometry}
            material={materials.BlueWeapon}
            rotation={[83, Math.PI, Math.PI]}
            scale={1.2}
            position={[0, 1.6, 0]}
          />
        </Chest>

        <mesh position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} receiveShadow>
          <boxBufferGeometry attach="geometry" />
          <shadowMaterial
            attach="material"
            color="#000"
            opacity={0.2}
            transparent
          />
        </mesh>
      </Canvas>
    </div>
  );
};

export default ScriptChestScene;
