import classNames from 'classnames';
import React from 'react';
import {ButtonSize} from '../../buttons/yellowShort/StemmyYellowButton';
import './Button.scoped.css';

type ButtonProps = {
  btnSize?: ButtonSize;
  noRadius?: boolean;
  disableHover?: boolean;
  buttonStyle?: SimpleButtonStyleDef;
  isSelected?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface SimpleButtonStyleDef {
  button: string;
  hover: string;
  selected: string;
}

interface SimpleButtonStyleObject {
  [name: string]: SimpleButtonStyleDef;
}

export const ButtonStyleCoffee: SimpleButtonStyleObject = {
  Common: {
    button:
      'grow bg-[#A0765F] font-roboto font-bold text-white text-sm px-8 py-2 rounded-lg',
    hover: 'ease-in duration-300 hover:bg-[#A0765F]/70',
    selected: '',
  },
  Blank: {
    button:
      'text-[#A0765F] border border-[#A0765F] font-roboto font-bold text-sm px-8 py-2 rounded-lg',
    hover: 'ease-in duration-300 hover:bg-[#A0765F]/80 hover:text-white',
    selected: '',
  },
};

export const ButtonStyleSimple: SimpleButtonStyleObject = {
  Common: {
    button:
      'bg-[#02c9bf] border border-[#02c9bf] font-bold text-white text-base px-8 py-2 rounded-lg min-h-[48px]',
    hover: 'ease-in duration-300 hover:bg-[#42E8E0]',
    selected: '',
  },
  Blank: {
    button:
      'bg-[#464646] border border-[#42E8E0] font-bold text-[#42E8E0] text-base px-8 py-2 rounded-lg min-h-[48px]',
    hover: 'ease-in duration-300 hover:bg-[#2E2D2D] hover:text-[#42E8E0]',
    selected: '',
  },
};

// className применяем к трем разным блокам(button и двум div из которых состоит вся кнопка). Так получилось, что у нас кнопка состоит из трех частей: 1) подложка, 2) над ней наибольшая видимая часть всей кнопки и немного поднятая, чтобы от подложки осталась только внизу полоска, 3) от середины вверх идет светлая часть. И когда рендерят с кастомным стилем <Button className="123">, стиль 123 применяется сразу к трем этим частям кнопки. Или можно на выбор. Раньше он применялся только к одному блоку <button> (82 строка), потому что кнопка состояла из двух частей, этого button и светлой верхней части, которая была на 80% прозрачна и можно было применить стиль только к button. Но теперь если бы мы продолжали применять кастомные классы только к одной части кнопки, в итоге остальные не будут менять стиль и выглядит в итоге не очень.
const SimpleButton: React.FC<ButtonProps> = ({
  className,
  type,
  children,
  btnSize,
  onClick,
  buttonStyle = ButtonStyleSimple.Common,
  disableHover = false,
  noRadius = false,
  disabled = false,
  isSelected = false,
  ...rest
}) => (
  <button
    type={type}
    disabled={disabled}
    className={classNames(
      btnSize,
      !noRadius && 'rounded-md',
      !disableHover && buttonStyle.hover,
      buttonStyle.button,
      isSelected && buttonStyle.selected,
      'text-center cursor-pointer flex justify-center items-center relative overflow-hidden',
      className,
    )}
    onClick={onClick}
    {...rest}
  >
    <span className="z-30 pointer-events-none">{children}</span>
  </button>
);
export default SimpleButton;
