import MockAdapter from 'axios-mock-adapter/types';
import {GetDialogByIdRequest} from '../request/GetDialogRequest';
import {DialogDatabase} from './DialogsMockDB';

export const UseDialogMocks = (adapter: MockAdapter): void => {
  adapter
    .onPost(/\/Integration\/Dialog\/GetById/)
    .reply<GetDialogByIdRequest>(({data}) => {
      const parsedData = JSON.parse(data) as GetDialogByIdRequest;
      if (!parsedData) return [404, {}];
      const dialog = DialogDatabase[parsedData.dialogId];
      if (!dialog) return [404, {}];

      return [200, dialog];
    });
};
