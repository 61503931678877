import {useRef} from 'react';
import * as THREE from 'three';
import {useLoader, useFrame} from '@react-three/fiber';
import {Vector3} from 'three';

interface ParticleSmokeProps {
  index: number;

  length?: number;
  distance?: number;
  horizontalSpread?: number;
}

const ParticleSmoke: React.FC<ParticleSmokeProps> = ({
  index,
  length = 0.5,
  distance = 0.3,
  horizontalSpread = 0.2,
}) => {
  const texture = useLoader(
    THREE.TextureLoader,
    `/assets/smoke0${Math.floor(Math.random() * 7) + 1}.png`,
  );
  const ref = useRef<THREE.Mesh>() as React.MutableRefObject<THREE.Mesh>;

  useFrame(() => {
    ref.current.rotation.z += index % 3 === 0 ? -0.1 : 0.1;
    if (ref.current.position.y < -length) {
      ref.current.position.y = -0.1;
    } else {
      ref.current.position.y += -0.01;
    }
  });

  return (
    <mesh
      position={
        new Vector3(
          index % 2 === 0
            ? -distance - Math.random() * horizontalSpread
            : distance + Math.random() * horizontalSpread,
          -0.15 * (index / 20),
          -2.2,
        )
      }
      ref={ref}
    >
      <planeGeometry args={[0.2, 0.2]} />
      <meshLambertMaterial transparent opacity={1} map={texture} />
    </mesh>
  );
};
export default ParticleSmoke;
