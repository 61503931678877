import {useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  useGetDialog,
  useGetDialogOnRequest,
} from '../../../api/Integration/dialog/Dialog';
import {DialogIntegrationRef} from '../../../components/scriptIntegrations/DialogScriptIntegration';
import DialogEventHandler from '../../../components/scriptIntegrations/DialogScriptIntegration';
import {DialogPopupRefProps} from '../../../components/ui/dialog/contract';
import {
  BasicDialogProps,
  DialogWindowSize,
} from '../../../components/ui/dialog/types';
import DialogPopupManagerCoffee from '../../../components/ui/dialog/coffeeStyle/DialogPopupManager';

const DialogCoffeeWrapper: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [dialogId] = useState(searchParams.get('id'));
  const [forceFullscreen, setForceFullscreen] = useState(
    !!searchParams.get('fullscreen'),
  );
  const dialogRef = useRef<DialogPopupRefProps>(null);

  const [dialogElementProps, setDialogElements] = useState<BasicDialogProps>();

  const getDialogRequest = useGetDialogOnRequest({
    onSuccess: (data) => {
      setDialogElements(data);
    },
  });

  useGetDialog(
    {
      dialogId: dialogId || 'empty',
    },
    {
      onSuccess: (data) => {
        setDialogElements(data);
      },
    },
  );

  const dialogIntegrationRef = useRef<DialogIntegrationRef>(null);

  return (
    <>
      <DialogEventHandler
        ref={dialogIntegrationRef}
        setBack={() => {
          dialogRef.current?.back();
        }}
        setNext={() => {
          dialogRef.current?.next();
        }}
        setFallback={() => {
          dialogRef.current?.fallback();
        }}
        setFullscreen={(isFullscreen) => {
          setForceFullscreen(isFullscreen);
        }}
        loadScenario={(id) => {
          getDialogRequest.mutate({dialogId: id});
        }}
        setFinalSlide={() => {
          dialogRef.current?.setLastSlide();
        }}
        setFirstSlide={() => {
          dialogRef.current?.setFirstSlide();
        }}
      />

      {dialogElementProps && (
        <DialogPopupManagerCoffee
          {...dialogElementProps}
          windowSize={
            forceFullscreen
              ? DialogWindowSize.Fullscreen
              : dialogElementProps.windowSize
          }
          ref={dialogRef}
          onClick={dialogIntegrationRef.current?.onButtonClick}
          onHeightChanged={dialogIntegrationRef.current?.onHeightChanged}
          onWidthChanged={dialogIntegrationRef.current?.onWidthChanged}
          onStart={dialogIntegrationRef.current?.onStart}
          onFinish={dialogIntegrationRef.current?.onFinish}
          onNext={dialogIntegrationRef.current?.onNext}
          onBack={dialogIntegrationRef.current?.onBack}
          onFallback={dialogIntegrationRef.current?.onFallback}
          onClosed={dialogIntegrationRef.current?.onClosed}
        />
      )}
    </>
  );
};

export default DialogCoffeeWrapper;
