import {animated, config, useSpring} from '@react-spring/three';
import {useAnimations, useGLTF} from '@react-three/drei';
import {useFrame} from '@react-three/fiber';
import React, {useCallback} from 'react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';
import {Vector3} from 'three';
import {ModelTransform} from '../../models/types/basic';
import {ChestModel} from '../../../types/models/ChestModel';
import GameSound, {GameSoundRefProps} from '../effects/Sound';

export interface ChestRefProps {
  idle: () => void;
  open: () => void;
  opened: () => void;
  spawn: () => void;
}

export interface ChestProps extends Omit<ModelTransform, 'position'> {
  children: React.ReactNode;
  isAutoOpen?: boolean;
  onFinished?: () => void;
}

const Chest = forwardRef<ChestRefProps, ChestProps>(
  ({rotation, scale, children, onFinished, isAutoOpen = true}, ref) => {
    const openChestSound = useRef<GameSoundRefProps>(null);
    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
    const {nodes, materials, animations} = useGLTF(
      'https://storage.googleapis.com/stemmy-integrations/models/others/Chest.glb',
    ) as unknown as ChestModel;
    const {actions} = useAnimations(animations, group);

    const coinMesh = React.createRef<THREE.Group>();

    const [isSpawned, setIsSpawned] = useState(false);
    const [isLootSpawned, setIsLootSpawned] = useState(false);

    const {chestPosition} = useSpring({
      chestPosition: isSpawned ? 0 : 4,
      config: config.wobbly,
    });

    const {lootPosition} = useSpring({
      lootPosition: isLootSpawned ? 0 : -1.5,
      config: config.wobbly,
    });

    useFrame(() => {
      if (coinMesh.current) {
        coinMesh.current.rotateOnAxis(new Vector3(0, 1, 0), 0.04);
      }
    });

    const openChest = useCallback(() => {
      if (isLootSpawned) return;

      const animation = actions.Open;
      setTimeout(() => {
        setIsLootSpawned(true);
      }, 300);
      openChestSound.current?.play();

      actions.Idle?.stop().reset();

      if (!animation) return;
      animation.setLoop(THREE.LoopOnce, 1);
      animation.clampWhenFinished = true;
      animation.enabled = true;
      animation.play();

      if (onFinished) onFinished();
    }, [actions.Idle, actions.Open, isLootSpawned, onFinished]);

    useEffect(() => {
      const animation = actions.Idle;
      if (!animation) return;
      animation.play();

      setTimeout(() => {
        setIsSpawned(true);
      }, 500);

      if (isAutoOpen) {
        setTimeout(() => {
          openChest();
        }, 5000);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      idle() {
        const animation = actions.Idle;
        if (!animation) return;
        animation.play();
      },
      open() {
        openChest();
      },
      opened() {
        // const animation = actions.Opened;
        // if (!animation) return;
        // animation.play();
      },
      spawn() {
        // const animation = actions.Spawn;
        // if (!animation) return;
        // animation.setLoop(THREE.LoopOnce, 1);
        // animation.clampWhenFinished = true;
        // animation.enabled = true;
        // animation.play().reset();
        setIsSpawned(true);
      },
    }));

    return (
      <>
        <GameSound
          ref={openChestSound}
          src="https://storage.googleapis.com/stemmy-integrations/sfx/chestOpen.mp3"
        />
        <animated.group
          ref={group}
          // position={position}
          rotation={rotation}
          scale={scale}
          dispose={null}
          position-y={chestPosition}
        >
          <group name="Scene" rotation={[0, Math.PI, 0]}>
            <group name="Armature">
              <primitive object={nodes.Body} />
              <skinnedMesh
                name="ChestBody"
                geometry={nodes.ChestBody.geometry}
                material={materials.Def}
                skeleton={nodes.ChestBody.skeleton}
                onClick={() => {
                  openChest();
                }}
                //   position={springAnimation.position}
                //position-y={lootPosition}
              />
            </group>
          </group>
          {isLootSpawned && (
            <animated.group position-y={lootPosition} ref={coinMesh}>
              {children}
            </animated.group>
          )}
        </animated.group>
      </>
    );
  },
);
export default Chest;
