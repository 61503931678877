import classNames from 'classnames';
import {motion} from 'framer-motion';
import {useEffect} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {useResizeDetector} from 'react-resize-detector';
import {SliderAnimationVariants} from '../../../styles/framerMotionVariants';
import {DialogElement, DialogWindowSize} from '../types';
import ButtonsListCoffee from './ButtonsList';

interface DialogPopupElementProps {
  prefixId: string;
  element: DialogElement;
  windowSize?: DialogWindowSize;

  onClick: (id: string) => void;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
  onFallback: () => void;
  onJumpToSlide: (val: number) => void;
  onClosed: () => void;

  onWidthChanged?: (width: number) => void;
  onHeightChanged?: (heigth: number) => void;
}

const DialogPopupElementSimple: React.FC<DialogPopupElementProps> = ({
  element: {name, text, buttons, testButtons, character},
  prefixId,
  windowSize,
  onClick,
  onBack,
  onNext,
  onFinish,
  onFallback,
  onJumpToSlide,
  onClosed,

  onWidthChanged,
  onHeightChanged,
}) => {
  const {width, height, ref} = useResizeDetector({
    refreshRate: 200,
  });

  useEffect(() => {
    if (!height || !onHeightChanged) return;
    onHeightChanged(height);
  }, [height, onHeightChanged]);

  useEffect(() => {
    if (!width || !onWidthChanged) return;
    onWidthChanged(width);
  }, [onWidthChanged, width]);

  return (
    <div
      ref={ref}
      className={classNames(
        'absolute',
        windowSize === DialogWindowSize.Fullscreen
          ? 'w-full max-w-2xl'
          : 'w-fit max-w-md',
      )}
    >
      <div
        className={classNames(
          'p-4 relative font-roboto',
          character ? 'my-8' : 'mt-2 mb-6',
        )}
      >
        <motion.div
          initial={'hidden'}
          animate={'visible'}
          transition={{duration: 0.25}}
          variants={SliderAnimationVariants}
          className={classNames(
            'border-2 border-[#A0765F] relative rounded-lg bg-white shadow-lg text-black cursor-default h-fit p-3 lg:p-5',
            'flex flex-col gap-2',
            character && 'ml-40',
          )}
        >
          <div
            className="absolute right-2 top-2 text-[#C4C5C7] hover:text-black cursor-pointer"
            onClick={() => onClosed()}
          >
            <AiOutlineClose />
          </div>
          {name && <div className="font-bold text-2xl">{name}</div>}

          <p className="text-lg text-black/80 mt-2">{text}</p>

          {buttons && (
            <div className="mt-4 justify-center flex flex-row-reverse gap-2 md:gap-3.5 z-20">
              <ButtonsListCoffee
                prefixId={prefixId}
                buttons={buttons}
                onClick={onClick}
                onFinish={onFinish}
                onFallback={onFallback}
                onBack={onBack}
                onNext={onNext}
                onJumpToSlide={onJumpToSlide}
              />
            </div>
          )}

          {testButtons && (
            <div className="mt-4 grid grid-cols-2 mt-1 gap-3.5 z-20">
              <ButtonsListCoffee
                prefixId={prefixId}
                buttons={testButtons}
                onClick={onClick}
                onFinish={onFinish}
                onFallback={onFallback}
                onBack={onBack}
                onNext={onNext}
                onJumpToSlide={onJumpToSlide}
              />
            </div>
          )}
        </motion.div>

        {character && (
          <div
            className={classNames(
              'absolute bottom-[-30px] left-[-10px] w-56 h-full',
              'bg-contain bg-no-repeat bg-center',
            )}
            style={{
              backgroundImage: 'url("/integrations/coffee_character.png")',
            }}
          >
            {/* <img src={character.image} className="w-56 object-contain" /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogPopupElementSimple;
