import {useAnimations, useGLTF} from '@react-three/drei';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';
import {AnimationAction} from 'three';
import {ModelTransform} from '../../../components/models/types/basic';
import {BodyModel} from '../../../types/models/BodyModel';
import {FootballModel} from '../../../types/models/FootballModel';
import {HeadModel} from '../../../types/models/HeadModel';

export interface FootballCharacterPropsRefProps {
  //   speak: () => void;
  //   takeoff: () => void;
  //   land: () => void;
  //   dance: () => void;
  kick: () => void;
  kneeing: () => void;
  powerKick: () => void;
}

interface FootballCharacterProps extends ModelTransform {
  headModel?: HeadModel;
  bodyModel?: BodyModel;
}

const FootballCharacter = forwardRef<
  FootballCharacterPropsRefProps,
  FootballCharacterProps
>(({headModel, bodyModel, position, rotation, scale}, ref) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials, animations} = useGLTF(
    '/models/football/PlayeFootBall.glb',
  ) as unknown as FootballModel;
  const {actions, mixer} = useAnimations(animations, group);

  const [activeAction, setActiveAction] = useState<THREE.AnimationAction>();

  const fadeAction = useCallback(
    (toAction: THREE.AnimationAction | null) => {
      if (!toAction) return;
      if (toAction != activeAction) {
        if (activeAction) {
          console.log('Fading out');
          activeAction.fadeOut(0.2);
        }
        toAction.reset();
        toAction.fadeIn(0);
        toAction.play();

        setActiveAction(toAction);
      }
    },
    [activeAction],
  );

  // const setAction = useCallback(
  //   (toAction: THREE.AnimationAction | null) => {
  //     if (!toAction) return;

  //     if (toAction != activeAction) {
  //       if (activeAction) {
  //         activeAction.stop();
  //       }

  //       toAction.reset();
  //       toAction.play();

  //       setActiveAction(toAction);
  //     }
  //   },
  //   [activeAction],
  // );

  const onAnimationFinished = useCallback(
    (
      e: THREE.Event & {
        type: 'finished';
      } & {
        target: THREE.AnimationMixer;
        action?: AnimationAction;
      },
    ) => {
      console.log('On finished triggered');
      switch (e.action) {
        case actions.Kick:
          console.log('Auto change to Idle');
          fadeAction(actions.Idle);
          break;

        case actions['PowerKick ']:
          console.log('Auto change to Idle');
          fadeAction(actions.Idle);
          break;
      }
    },
    [actions, fadeAction],
  );

  useEffect(() => {
    fadeAction(actions.Idle);
    mixer.addEventListener('finished', onAnimationFinished);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    // speak() {
    //   const idleanimation = actions.IdleJetOFF;
    //   if (idleanimation) {
    //     fadeAction(idleanimation);
    //   }
    // },
    // takeoff() {
    //   const takeOffAnimation = actions.IdleJetStart;
    //   if (takeOffAnimation) {
    //     takeOffAnimation.clampWhenFinished = true;
    //     takeOffAnimation.setLoop(THREE.LoopOnce, 1);
    //     fadeAction(takeOffAnimation);
    //   }
    // },
    // land() {
    //   const landAnimation = actions.IdleJetEnd;
    //   if (landAnimation) {
    //     landAnimation.clampWhenFinished = true;
    //     landAnimation.setLoop(THREE.LoopOnce, 1);
    //     fadeAction(landAnimation);
    //   }
    // },
    // dance() {
    //   fadeAction(actions.Dance);
    // },
    kick() {
      const kickAnimation = actions.Kick;
      if (kickAnimation) {
        kickAnimation.clampWhenFinished = false;
        kickAnimation.setLoop(THREE.LoopOnce, 1);
        fadeAction(kickAnimation);
      }
    },
    kneeing() {
      const kneeingAnimation = actions.Kneeing;
      if (kneeingAnimation) {
        fadeAction(kneeingAnimation);
      }
    },
    powerKick() {
      const powerKickAnimation = actions['PowerKick '];
      if (powerKickAnimation) {
        powerKickAnimation.clampWhenFinished = false;
        powerKickAnimation.setLoop(THREE.LoopOnce, 1);
        fadeAction(powerKickAnimation);
      }
    },
  }));

  return (
    <group
      ref={group}
      position={position}
      rotation={rotation}
      scale={scale}
      dispose={null}
    >
      <group name="Scene">
        <group name="Armature">
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh
            name="BODY"
            geometry={
              bodyModel ? bodyModel.nodes.BODY.geometry : nodes.BODY.geometry
            }
            material={materials.BodiesPBRDefault}
            skeleton={nodes.BODY.skeleton}
          />
          <skinnedMesh
            name="HEAD"
            geometry={
              headModel ? headModel.nodes.HEAD.geometry : nodes.HEAD.geometry
            }
            material={materials.HeadsPBRDefault}
            skeleton={nodes.HEAD.skeleton}
          />
        </group>
      </group>
    </group>
  );
});
export default FootballCharacter;
