import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import {BackpackModel} from '../../../types/models/v2/accessories/Backpack';
import {HatModel} from '../../../types/models/v2/accessories/Hat';
import {ShieldModel} from '../../../types/models/v2/accessories/Shield';
import {WeaponModel} from '../../../types/models/v2/accessories/Weapon';
import {FighterWithBaseModel} from '../../../types/models/v2/bases/FighterWithRigBase';
import {BasicBodyModel} from '../../../types/models/v2/bodies/BasicBody';
import {BasicHeadModel} from '../../../types/models/v2/heads/BasicHead';
import {DefaultModel} from '../types/basic';

export interface FighterCharacterAccessories {
  headModel: BasicHeadModel;
  bodyModel: BasicBodyModel;

  backPackModel?: BackpackModel;
  hatModel?: HatModel;
  shieldModel?: ShieldModel;
  weaponModel?: WeaponModel;
}

interface FighterCharacterProps
  extends DefaultModel,
    FighterCharacterAccessories {}

const FighterCharacter: React.FC<FighterCharacterProps> = ({
  onLoaded,
  headModel,
  bodyModel,

  backPackModel,
  hatModel,
  shieldModel,
  weaponModel,

  position,
  rotation,
  scale,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes} = useGLTF(
    '/models/v2/bases/FightRigWithEmptyOBJ.glb',
  ) as unknown as FighterWithBaseModel;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
      castShadow
    >
      <primitive object={nodes.mixamorigHips} />
      <skinnedMesh
        geometry={
          backPackModel
            ? backPackModel.nodes.BACKPACK.geometry
            : nodes.EMPTYBackPack.geometry
        }
        material={
          backPackModel
            ? backPackModel.nodes.BACKPACK.material
            : nodes.EMPTYBackPack.material
        }
        skeleton={nodes.EMPTYBackPack.skeleton}
        castShadow
      />
      <skinnedMesh
        geometry={
          bodyModel ? bodyModel.nodes.BODY.geometry : nodes.EMPTYBody.geometry
        }
        material={
          bodyModel ? bodyModel.nodes.BODY.material : nodes.EMPTYBody.material
        }
        skeleton={nodes.EMPTYBody.skeleton}
        castShadow
      />
      <skinnedMesh
        geometry={
          hatModel ? hatModel.nodes.HAT.geometry : nodes.EMPTYHat.geometry
        }
        material={
          hatModel ? hatModel.nodes.HAT.material : nodes.EMPTYHat.material
        }
        skeleton={nodes.EMPTYHat.skeleton}
        castShadow
      />
      <skinnedMesh
        geometry={
          headModel ? headModel.nodes.HEAD.geometry : nodes.EMPTYHead.geometry
        }
        material={
          headModel ? headModel.nodes.HEAD.material : nodes.EMPTYHead.material
        }
        skeleton={nodes.EMPTYHead.skeleton}
        castShadow
      />
      <skinnedMesh
        geometry={
          shieldModel
            ? shieldModel.nodes.SHIELD.geometry
            : nodes.EMPTYShield.geometry
        }
        material={
          shieldModel
            ? shieldModel.nodes.SHIELD.material
            : nodes.EMPTYShield.material
        }
        skeleton={nodes.EMPTYShield.skeleton}
        castShadow
      />
      <skinnedMesh
        geometry={
          weaponModel
            ? weaponModel.nodes.WEAPON.geometry
            : nodes.EMPTYWeapon.geometry
        }
        material={
          weaponModel
            ? weaponModel.nodes.WEAPON.material
            : nodes.EMPTYWeapon.material
        }
        skeleton={nodes.EMPTYWeapon.skeleton}
        castShadow
      />
    </group>
  );
};

export default FighterCharacter;
