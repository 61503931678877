import {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import * as THREE from 'three';
import {ModelTransform} from '../../../types/basic';
import ParticleSmoke from './ParticleSmoke';

export interface SmokeEffectRefProps {
  activateSmoke: () => void;
  diactivateSmoke: () => void;
}

interface SmokeEffectProps extends ModelTransform {
  autostart?: boolean;
}

const SmokeEffect = forwardRef<SmokeEffectRefProps, SmokeEffectProps>(
  ({position, rotation, autostart = false}, ref) => {
    const [smokeParticles] = useState(
      new Array(100).fill(1).map(() => Math.random()),
    );

    const [active, setActive] = useState(autostart);

    const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;

    useImperativeHandle(ref, () => ({
      activateSmoke() {
        setActive(true);
      },
      diactivateSmoke() {
        setActive(false);
      },
    }));

    return (
      <group
        position={position}
        ref={group}
        rotation={rotation}
        visible={active}
      >
        {smokeParticles.map((p, index) => {
          return (
            <ParticleSmoke
              key={index}
              index={index}
              distance={0.05}
              length={0.7}
            />
          );
        })}
      </group>
    );
  },
);
export default SmokeEffect;
