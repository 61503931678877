import classNames from 'classnames';
import {TournamentTableLeadrboardTableProperty} from '../Types';

interface LeaderboardSimpleLineProps {
  place: number;
  name: string;
  isHighlightet?: boolean;
  customProperties: TournamentTableLeadrboardTableProperty[];
}

const LeaderboardSimpleLine: React.FC<LeaderboardSimpleLineProps> = ({
  place,
  name,
  isHighlightet,
  customProperties,
}) => (
  <tr
    className={classNames(
      'p-3 text-sm',
      isHighlightet ? 'bg-[#42E8E0]/10' : 'even:bg-[#464646]/20',
    )}
  >
    <td className="font-semibold pl-5 py-3 flex gap-2 items-center">
      <span>{place.toLocaleString('en', {useGrouping: true})}</span>
      <span>
        {place === 1 && (
          <img className="h-6" src="/assets/icons/medal-gold.png" />
        )}
        {place === 2 && (
          <img className="h-6" src="/assets/icons/medal-silver.png" />
        )}
        {place === 3 && (
          <img className="h-6" src="/assets/icons/medal-bronze.png" />
        )}
      </span>
    </td>

    <td className="w-full">{name}</td>

    <td className="pr-5 py-3">
      {customProperties.map((x, index) => (
        <div
          key={index}
          className={classNames(
            'space-x-2 float-right',
            isHighlightet && 'text-[#02C9BF]',
          )}
        >
          <span className={classNames(isHighlightet && 'text-[#02C9BF]')}>
            {Number(x.value).toLocaleString('en', {
              useGrouping: true,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
          <span className="text-[#92939C]">USDT</span>
        </div>
      ))}
    </td>
  </tr>
);

export default LeaderboardSimpleLine;
