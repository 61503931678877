import {useEffect, useCallback} from 'react';
import {useGetDynamicUserTournament} from '../../../api/Integration/tournament/TournamentMutations';
import {TournamentTableBasicProps} from '../../ui/tournamentTable/Types';

interface TournamentTableUiWrapperProps {
  tournamentId: string;
  userId?: string;
  rowsToShow: number;
  uiElement: React.FC<TournamentTableBasicProps>;

  onLoaded: () => void;
}

const TournamentTableUiWrapper: React.FC<TournamentTableUiWrapperProps> = ({
  userId,
  tournamentId,
  rowsToShow,
  uiElement,
  onLoaded,
}) => {
  const tournamentData = useGetDynamicUserTournament(tournamentId);

  useEffect(() => {
    tournamentData.mutate({
      userId,
      rows: rowsToShow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsToShow, userId]);

  useEffect(() => {
    if (tournamentData.isSuccess) onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentData.isSuccess]);

  const getContent = useCallback(() => {
    if (!tournamentData.isSuccess) return;

    return uiElement({
      lines: tournamentData.data.map((x) => ({
        place: x.position + 1,
        name: x.name,
        isHighlightet: x.isSelf,
        customProperties: [{value: x.result.toString()}],
      })),
      title: 'This is a title',
      text: 'Irure mollit adipisicing qui ea consequat. Duis velit eu dolore officia proident mollit proident proident nulla ullamco sint id eiusmod. Labore tempor exercitation cillum in Lorem labore dolore consectetur. Ea veniam tempor aliquip incididunt ea et elit. Dolor laborum consectetur mollit est commodo. Ex sint amet ex occaecat anim nulla. Laboris dolore elit dolore eu officia velit.',
    });
  }, [tournamentData.data, tournamentData.isSuccess, uiElement]);

  return <>{getContent()}</>;
};

export default TournamentTableUiWrapper;
