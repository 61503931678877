import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Achievement from '../../pages/scriptIntegrations/achievement/Achievement';
import SampleAnimationTest from '../../pages/scriptIntegrations/character/sample/SampleAnimationTest';
import ScriptChestScene from '../../pages/scriptIntegrations/chest/ChestOpenScene';
import FootballMinigameScene from '../../pages/scriptIntegrations/football/Football';
import SuspenseLayout from '../layouts/basicLayout/SuspenseLayout';
import JetpackSuitWrapper from '../../pages/scriptIntegrations/jetpack/JetpackSuit';
import DialogSimpleWrapper from '../../pages/scriptIntegrations/dialog/DialogSimpleWrapper';
import TournamentTable from '../../pages/scriptIntegrations/tournamentTable/SimpleTournamentTable';
import GameTournamentTable from '../../pages/scriptIntegrations/tournamentTable/GameTournamentTable';
import SaveWrapper from '../../pages/scriptIntegrations/save/SaveWrapper';
import JetpackGirlWrapper from '../../pages/scriptIntegrations/jetpack/JetpackGirl';
import DialogCoffeeWrapper from '../../pages/scriptIntegrations/dialog/DialogCoffeeWrapper';
import StatisticsWrapper from '../../pages/scriptIntegrations/statistics/StatisticsWrapper';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      {/* <Route path="/">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          <Route index element={<HereIntegrationWrapper />} />
          <Route
            path="/demo/chest"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 3,
                }}
              />
            }
          />
          <Route
            path="/demo/constructor"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 4,
                }}
              />
            }
          />
          <Route
            path="/demo/tournament"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 6,
                }}
              />
            }
          />
          <Route
            path="/demo/fight"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 2,
                }}
              />
            }
          />
          <Route
            path="/demo/onboarding"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 10,
                }}
              />
            }
          />
          <Route path="/testintegration" element={<HereIntegrationWrapper />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/uiExample" element={<UiExamples />} />
        </Route>
      </Route> */}

      <Route path="/components">
        <Route element={<SuspenseLayout />}>
          <Route path="/components/chest" element={<ScriptChestScene />} />
          <Route path="/components/jetpack" element={<JetpackSuitWrapper />} />
          <Route
            path="/components/jetpack/girl"
            element={<JetpackGirlWrapper />}
          />
          <Route path="/components/dialog" element={<DialogSimpleWrapper />} />
          <Route
            path="/components/dialog/coffee"
            element={<DialogCoffeeWrapper />}
          />
          <Route path="/components/achievement" element={<Achievement />} />
          <Route
            path="/components/tournamentTable"
            element={<TournamentTable />}
          />
          <Route
            path="/components/tournamentTableGame"
            element={<GameTournamentTable />}
          />
          <Route path="/components/test" element={<SampleAnimationTest />} />
          <Route
            path="/components/football"
            element={<FootballMinigameScene />}
          />

          <Route path="/components/saver" element={<SaveWrapper />} />
          <Route
            path="/components/statistics"
            element={<StatisticsWrapper />}
          />
        </Route>
      </Route>

      {/* <Route path="/scene">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          <Route path="/scene/test" element={<FightSceneTest />} />
        </Route>
      </Route> */}
    </Routes>
  </BrowserRouter>
);
export default AppRouter;
