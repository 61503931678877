import React, {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import SuspenseLoader from '../../../components/suspenseLoader/SuspenseLoader';

interface SuspenseLayoutProps {
  background?: string;
}

const SuspenseLayout: React.FC<SuspenseLayoutProps> = ({background}) => (
  <div className="overflow-hidden">
    {/* <Suspense fallback={<SuspenseLoader background={background} />}> */}
    <Suspense>
      <Outlet />
    </Suspense>
  </div>
);
export default SuspenseLayout;
