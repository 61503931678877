import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {GateSector} from '../../../../pages/scriptIntegrations/football/Football';

interface FootballSectorProps {
  questionAnswer: GateSector;

  x: number;
  y: number;

  isProhibitClick: boolean;
  isShowAnswer: boolean;

  onSelected: (id: string, x: number, y: number, isCorrect: boolean) => void;
}

const FootballSector: React.FC<FootballSectorProps> = ({
  questionAnswer,
  x,
  y,
  isProhibitClick,
  isShowAnswer,
  onSelected,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(false);
  }, [questionAnswer]);

  return (
    <div
      className={classNames(
        'border-4 md:border-8 h-full flex justify-center items-center font-roboto font-medium',
        isShowAnswer
          ? questionAnswer.isCorrect
            ? 'border-green-400'
            : 'border-red-400'
          : 'border-gray-400 cursor-pointer',

        isSelected ? 'bg-gray-300/50' : 'hover:bg-gray-200/50',
      )}
      onClick={() => {
        if (isProhibitClick) return;
        setIsSelected(true);
        onSelected(questionAnswer.id, x, y, questionAnswer.isCorrect);
      }}
    >
      {questionAnswer.title}
    </div>
  );
};

export default FootballSector;
