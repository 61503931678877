import {useCallback, useRef, useState} from 'react';
import {
  StatisticsAuthGettingEvent,
  StatisticsAuthSendingEvent,
  StatisticsGettingEvent,
} from '../../pages/scriptIntegrations/statistics/StatisticsTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';

interface StatisticsEventHandlerProps {
  onAuthoriseRequest: (data: string) => void;
}

const StatisticsEventHandler: React.FC<StatisticsEventHandlerProps> = ({
  onAuthoriseRequest,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const eventMessangerRef =
    useRef<EventSystemRefProps<StatisticsAuthSendingEvent>>(null);

  const incomingEventsHandler = useCallback(
    (message: StatisticsAuthGettingEvent | StatisticsGettingEvent) => {
      switch (message.event) {
        case 'authorise':
          onAuthoriseRequest(message.platformId);
          break;

        case 'checkLoaded':
          if (isLoaded) {
            eventMessangerRef.current?.sendMessage(
              {
                event: 'onLoaded',
              },
              'statistics',
            );
          }
          break;
      }
    },
    [isLoaded, onAuthoriseRequest],
  );

  const OnLoaded = useCallback(() => {
    setIsLoaded(true);
    eventMessangerRef.current?.sendMessage(
      {
        event: 'onLoaded',
      },
      'statistics',
    );
  }, []);

  return (
    <EventsMessanger<
      StatisticsAuthGettingEvent | StatisticsGettingEvent,
      StatisticsAuthSendingEvent
    >
      componentName="statistics"
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
};

export default StatisticsEventHandler;
