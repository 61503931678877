import {PopupButton} from '../button/types';

export interface BasicDialogProps {
  id: string;
  prefixId: string;
  elements: DialogElement[];
  windowSize?: DialogWindowSize;
}

export interface DialogElement {
  id: string;
  name?: string;
  text?: string;
  buttons?: PopupButton[];
  testButtons?: PopupButton[];

  fallback?: DialogElement;
  character?: {
    image: string;
  };
}

export enum DialogWindowSize {
  Auto = 'w-auto',
  Big = 'w-3/4',
  Fullscreen = '',
}
