import {useRef, useState} from 'react';
import {OnboardingJetpackRef} from '../../../components/animatedCharacters/onboardingJetpack/Contract';
import SuitManJetpack from '../../../components/animatedCharacters/onboardingJetpack/SuitManJetpack';
import JetpackScriptIntegration from '../../../components/scriptIntegrations/JetpackIntegration';

const JetpackSuitWrapper: React.FC = () => {
  const characterRef = useRef<OnboardingJetpackRef>(null);

  const [jetpackLoaded, setJetpackLoaded] = useState<boolean>(false);

  return (
    <JetpackScriptIntegration
      characterRef={characterRef}
      isAnimationLoaded={jetpackLoaded}
    >
      <SuitManJetpack
        onLoaded={() => {
          setJetpackLoaded(true);
        }}
        ref={characterRef}
      />
    </JetpackScriptIntegration>
  );
};

export default JetpackSuitWrapper;
