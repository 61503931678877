import mixpanel from 'mixpanel-browser';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {IdBuilder} from '../../../../utils/idBuilder';
import {DialogPopupBaseProps, DialogPopupRefProps} from '../contract';
import {BasicDialogProps, DialogElement, DialogWindowSize} from '../types';
import DialogPopupElementSimple from './DialogPopupElement';

interface DialogPopupManagerProps
  extends DialogPopupBaseProps,
    BasicDialogProps {}

const DialogPopupManagerSimple = forwardRef<
  DialogPopupRefProps,
  DialogPopupManagerProps
>(
  (
    {
      prefixId,
      id,
      elements: paramElements,
      active,
      windowSize = DialogWindowSize.Auto,
      onHeightChanged,
      onWidthChanged,
      onStart,
      onFinish,
      onClick,
      onNext,
      onBack,
      onFallback,
    },
    ref,
  ) => {
    const [popupId, setPopupId] = useState(IdBuilder(prefixId, id));
    const [elements, setElements] = useState(paramElements);

    const [currentElementIndex, setCurrentElementIndex] = useState(0);
    const [currentFallbackElement, setCurrentFallbackElement] =
      useState<DialogElement>();
    const [startTriggered, setStartTriggered] = useState(false);

    useEffect(() => {
      if (!active) setStartTriggered(false);
    }, [active]);

    const OnStartAction = useCallback(() => {
      setStartTriggered(true);
      // console.log(`STATISTICS: launched popup: "${popupId}"`);

      mixpanel.track('Popup launch', {
        prefix: prefixId,
        id: id,
      });

      if (onStart) onStart();
    }, [id, onStart, prefixId]);

    const OnFinishAnimation = useCallback(() => {
      setCurrentFallbackElement(undefined);
      if (onFinish) onFinish();
    }, [onFinish]);

    const OnNextAnimation = useCallback(() => {
      setCurrentFallbackElement(undefined);

      if (currentElementIndex + 1 >= elements.length) {
        OnFinishAnimation();
        return;
      }

      setCurrentElementIndex((x) => {
        const index = x + 1;
        return index;
      });

      if (onNext) onNext();
    }, [OnFinishAnimation, currentElementIndex, elements.length, onNext]);

    const OnBackAnimation = useCallback(() => {
      setCurrentFallbackElement(undefined);

      if (currentElementIndex <= 0) return;

      setCurrentElementIndex((x) => {
        const index = x - 1;
        return index;
      });

      if (onBack) onBack();
    }, [currentElementIndex, onBack]);

    const OnFallback = useCallback(() => {
      if (
        elements[currentElementIndex] &&
        elements[currentElementIndex].fallback
      ) {
        setCurrentFallbackElement(elements[currentElementIndex].fallback);
      }

      if (onFallback) onFallback();
    }, [currentElementIndex, elements, onFallback]);

    const OnJumpToSlide = useCallback(
      (val: number) => {
        setCurrentFallbackElement(undefined);

        if (val < 0 || val >= elements.length) return;

        setCurrentElementIndex(() => {
          const index = val;
          return index;
        });
      },
      [elements.length],
    );

    useImperativeHandle(ref, () => ({
      next: () => {
        OnNextAnimation();
      },
      back: () => {
        OnBackAnimation();
      },
      fallback: () => {
        OnFallback();
      },
      setFirstSlide: () => {
        OnJumpToSlide(0);
      },
      setLastSlide: () => {
        OnJumpToSlide(elements.length - 1);
      },
    }));

    useEffect(() => {
      if (!startTriggered && active) OnStartAction();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    useEffect(() => {
      setElements(paramElements);
      OnStartAction();

      return () => {
        setPopupId(IdBuilder(prefixId, id));
        setCurrentElementIndex(0);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramElements]);

    return (
      <DialogPopupElementSimple
        element={
          !currentFallbackElement
            ? elements[currentElementIndex]
            : currentFallbackElement
        }
        windowSize={windowSize}
        prefixId={prefixId}
        onClick={(buttonId) => {
          if (onClick) onClick(buttonId);
        }}
        onFinish={OnFinishAnimation}
        onFallback={OnFallback}
        onBack={OnBackAnimation}
        onNext={OnNextAnimation}
        onJumpToSlide={OnJumpToSlide}
        onHeightChanged={onHeightChanged}
        onWidthChanged={onWidthChanged}
      />
    );
  },
);

export default DialogPopupManagerSimple;
