import {useGLTF} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {useEffect, useState} from 'react';
import {useRef} from 'react';
import * as THREE from 'three';
import Button from '../../../../components/form/button/Button';
import {BasicBodyModel} from '../../../../types/models/v2/bodies/BasicBody';
import {BasicHeadModel} from '../../../../types/models/v2/heads/BasicHead';
import {InstantFadeAnimation} from '../../../../components/models/animations/AnimationFadePrefabs';
import AnimationLoader, {
  AnimationLoaderRefProps,
} from '../../../../components/models/animations/AnimationLoader';
import {RepeatOnceAnimation} from '../../../../components/models/animations/AnimationRepeatPrefabs';
import JetpackCharacter from '../../../../components/models/bases/JetpackCharacter';
import {JetpackAnimation} from '../../../../types/animations/JetpackAnimation';
import SmokeEffect, {
  SmokeEffectRefProps,
} from '../../../../components/models/effects/particle/smoke/SmokeEffect';
import {BackSide, Euler, Vector3} from 'three';
import {PrayingAnimation} from '../../../../types/animations/PrayingAnimation';
import FighterCharacter from '../../../../components/models/bases/FighterCharacter';
import CactusEnimy from '../../../../components/game/models/Cactus';

const SampleAnimationTest: React.FC = () => {
  const animatorRef =
    useRef<AnimationLoaderRefProps<JetpackAnimation | PrayingAnimation>>(null);

  const smokeRef = useRef<SmokeEffectRefProps>(null);

  const Head = useGLTF(
    '/models/v2/heads/Head1.glb',
  ) as unknown as BasicHeadModel;
  const Body = useGLTF(
    '/models/v2/bodies/Body6.glb',
  ) as unknown as BasicBodyModel;

  const anims1 = useGLTF('/models/v2/animations/JetPackAnimations.glb')
    .animations as JetpackAnimation[];

  const anims2 = useGLTF('/models/v2/animations/PrayingAndDance.glb')
    .animations as PrayingAnimation[];

  const [characterGroup, setCharacterGroup] = useState<THREE.Group>();

  useEffect(() => {
    // Head.materials.Palete.roughness = 0;
    // Body.materials.Palete.specular = new Color('#000');
    // const test = Head.materials.Palete as unknown as MeshPhongMaterial;
    // Head.materials.Palete.metalness = 0.1;
    // materials.Palete.metalness = 0.3;
  }, []);

  return (
    <div className="h-screen">
      <Canvas
        shadows
        // camera={{rotation: [-0.3, 0, 0], position: [0, 2.3, 5], fov: 60}}
        camera={{rotation: [0, 0, 0], position: [0, 1.15, 5], fov: 34}}
      >
        <rectAreaLight
          color="#CCDBFF"
          position={[-3, 1.3, 3]}
          rotation={[0, -45, 0]}
          intensity={1.5}
          castShadow
        />
        <rectAreaLight
          color="#FFF"
          position={[3, 1.3, 3]}
          rotation={[0, 45, 0]}
          intensity={0.2}
          castShadow
        />
        <rectAreaLight
          color="#FFCFCC"
          position={[-2, 1.3, -4]}
          rotation={[0, -153, 0]}
          intensity={1.5}
          castShadow
        />

        {/* <directionalLight
          position={[0, 30, 0]}
          rotation={new Euler(0, 1, 0)}
          intensity={0.5}
          castShadow
          shadow-bias={0.00001}
          shadow-mapSize-height={1024}
          shadow-mapSize-width={1024}
        /> */}

        <FighterCharacter
          // rotation={new Euler(0, 0, 0)}
          headModel={Head}
          bodyModel={Body}
          onLoaded={(grp) => setCharacterGroup(grp)}
        />
        {/* <CactusEnimy position={new Vector3(1, 0, 0)} /> */}
        <mesh
          position={[0, -1, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          receiveShadow
        >
          <planeBufferGeometry attach="geometry" />
          <shadowMaterial
            attach="material"
            side={BackSide}
            color="#000"
            opacity={0.2}
            transparent
          />
        </mesh>

        <SmokeEffect position={new Vector3(0.0, 0.3, 1)} ref={smokeRef} />

        {characterGroup && (
          <AnimationLoader
            group={characterGroup}
            animations={[...anims1, ...anims2]}
            myRef={animatorRef}
            onAnimationFinished={(name) => {
              if (name == 'Flying') smokeRef.current?.diactivateSmoke();
            }}
            onAnimationStarted={(name) => {
              switch (name) {
                case 'Flying':
                  smokeRef.current?.activateSmoke();
              }
            }}
          />
        )}
      </Canvas>

      {characterGroup && (
        <div className="absolute bottom-0 flex">
          <div className="">
            <Button
              onClick={() => {
                smokeRef?.current?.activateSmoke();
              }}
            >
              Smoke
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('Praying');
              }}
            >
              Pray
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('HipHopDance');
              }}
            >
              Dance2
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('IDLE');
              }}
            >
              Idle
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('HandShake1');
              }}
            >
              Handshake
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('Flying');
              }}
            >
              Flying
            </Button>
          </div>
          <div className="">
            <Button
              onClick={() => {
                animatorRef.current?.animate('IDLE', {
                  repeatMode: RepeatOnceAnimation,
                  fadeMode: InstantFadeAnimation,
                });
              }}
            >
              Idle
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('HandShake1', {
                  repeatMode: RepeatOnceAnimation,
                  fadeMode: InstantFadeAnimation,
                });
              }}
            >
              Handshake
            </Button>

            <Button
              onClick={() => {
                animatorRef.current?.animate('Flying', {
                  repeatMode: RepeatOnceAnimation,
                  fadeMode: InstantFadeAnimation,
                });
              }}
            >
              Flying
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SampleAnimationTest;
