export { NOTIFICATION_CONTAINER, NOTIFICATION_INSERTION, NOTIFICATION_TYPE, NOTIFICATION_REMOVAL_SOURCE };
var NOTIFICATION_CONTAINER;
(function (NOTIFICATION_CONTAINER) {
    NOTIFICATION_CONTAINER["BOTTOM_LEFT"] = "bottom-left";
    NOTIFICATION_CONTAINER["BOTTOM_RIGHT"] = "bottom-right";
    NOTIFICATION_CONTAINER["BOTTOM_CENTER"] = "bottom-center";
    NOTIFICATION_CONTAINER["TOP_LEFT"] = "top-left";
    NOTIFICATION_CONTAINER["TOP_RIGHT"] = "top-right";
    NOTIFICATION_CONTAINER["TOP_CENTER"] = "top-center";
    NOTIFICATION_CONTAINER["CENTER"] = "center";
    NOTIFICATION_CONTAINER["TOP_FULL"] = "top-full";
    NOTIFICATION_CONTAINER["BOTTOM_FULL"] = "bottom-full";
})(NOTIFICATION_CONTAINER || (NOTIFICATION_CONTAINER = {}));
var NOTIFICATION_INSERTION;
(function (NOTIFICATION_INSERTION) {
    NOTIFICATION_INSERTION["TOP"] = "top";
    NOTIFICATION_INSERTION["BOTTOM"] = "bottom";
})(NOTIFICATION_INSERTION || (NOTIFICATION_INSERTION = {}));
var NOTIFICATION_TYPE;
(function (NOTIFICATION_TYPE) {
    NOTIFICATION_TYPE["SUCCESS"] = "success";
    NOTIFICATION_TYPE["DANGER"] = "danger";
    NOTIFICATION_TYPE["INFO"] = "info";
    NOTIFICATION_TYPE["DEFAULT"] = "default";
    NOTIFICATION_TYPE["WARNING"] = "warning";
})(NOTIFICATION_TYPE || (NOTIFICATION_TYPE = {}));
var NOTIFICATION_REMOVAL_SOURCE;
(function (NOTIFICATION_REMOVAL_SOURCE) {
    NOTIFICATION_REMOVAL_SOURCE["TIMEOUT"] = "timeout";
    NOTIFICATION_REMOVAL_SOURCE["CLICK"] = "click";
    NOTIFICATION_REMOVAL_SOURCE["TOUCH"] = "touch";
    NOTIFICATION_REMOVAL_SOURCE["MANUAL"] = "manual";
})(NOTIFICATION_REMOVAL_SOURCE || (NOTIFICATION_REMOVAL_SOURCE = {}));
