import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import herePart0_en from './en/herePart0.json';
import herePart1_en from './en/herePart1.json';
import herePart1Android_en from './en/herePart1Android.json';
import herePart2_en from './en/herePart2.json';
import herePartTournament_en from './en/hereTournamnet.json';
import universal_en from './en/universal.json';

import herePart0_in from './in/herePart0.json';
import herePart1_in from './in/herePart1.json';
import herePart1Android_in from './in/herePart1Android.json';
import herePart2_in from './in/herePart2.json';
import herePartTournament_in from './in/hereTournamnet.json';
import universal_in from './in/universal.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    detection: {
      order: ['querystring', 'cookie'],
      // caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        herePart0: herePart0_en,
        herePart1: herePart1_en,
        herePart1Android: herePart1Android_en,
        herePart2: herePart2_en,
        hereTournamnet: herePartTournament_en,
        universal: universal_en,
      },
      in: {
        herePart0: herePart0_in,
        herePart1: herePart1_in,
        herePart1Android: herePart1Android_in,
        herePart2: herePart2_in,
        hereTournamnet: herePartTournament_in,
        universal: universal_in,
      },
    },
  });

export default i18n;
