import {useAnimations} from '@react-three/drei';
import {
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import * as THREE from 'three';
import {AnimationAction, AnimationClip} from 'three';
import {getRandom} from '../../../utils/array';
import {
  DefaultFadeAnimation,
  InstantFadeAnimation,
} from './AnimationFadePrefabs';
import {LoopAnimation} from './AnimationRepeatPrefabs';

type AnimationFinished = THREE.Event & {
  type: 'finished';
} & {
  target: THREE.AnimationMixer;
  action?: AnimationAction;
};

export interface AnimationRepeatData {
  mode: THREE.AnimationActionLoopStyles;
  repeats: number;
}

export interface AnimationFadeData {
  fadeInDuration: number;
  fadeOutDuration: number;
  isReset: boolean;
}

interface AnimationLoaderProps<T extends AnimationClip> {
  group?: THREE.Group;
  animations: T[];

  onAnimationFinished?: (animationName: T['name']) => void;
  onAnimationStarted?: (animationName: T['name']) => void;

  baseAnimation?: T['name'];
  onLoaded?: () => void;
}

interface AnimationParams<T extends AnimationClip> {
  repeatMode?: AnimationRepeatData;
  fadeMode?: AnimationFadeData;
  duration?: number;
  clampWhenFinished?: boolean;
  onFinished?: AnimationCallback<T>;
}

export type AnimationCallback<T extends AnimationClip> = {
  callback?: () => void;
  launchBaseAnimation?: AnimationParams<T>;
  uses?: number;
};

export interface AnimationLoaderRefProps<T extends AnimationClip> {
  animate: (element: T['name'], parameters?: AnimationParams<T>) => void;
  animateRandom: (
    elements: T['name'][],
    parameters?: AnimationParams<T>,
  ) => void;
}

const AnimationLoader = <T extends AnimationClip>(
  props: AnimationLoaderProps<T> & {myRef: Ref<AnimationLoaderRefProps<T>>},
) => {
  const [onFinishCallbacks, setOnFinishedCallback] = useState<
    ({target: T['name']} & AnimationCallback<T>)[]
  >([]);

  const {actions, mixer} = useAnimations(props.animations, props.group);

  const [activeAction, setActiveAction] = useState<THREE.AnimationAction>();
  const fadeAction = useCallback(
    (
      toAction: THREE.AnimationAction,
      fadeIn: number,
      fadeOut: number,
      isReset: boolean,
    ) => {
      if (!toAction) return;
      console.debug(
        `Fading from ${activeAction?.getClip().name} to ${
          toAction.getClip().name
        }`,
      );
      if (toAction != activeAction) {
        if (activeAction) {
          activeAction.fadeOut(fadeOut);

          if (
            props.onAnimationFinished &&
            activeAction.loop !== THREE.LoopOnce
          ) {
            setTimeout(() => {
              if (props.onAnimationFinished && activeAction)
                props.onAnimationFinished(activeAction.getClip().name);
            }, fadeOut);
          }
        }

        if (isReset) {
          toAction.reset();
        }

        toAction.fadeIn(fadeIn);
        toAction.play();

        setActiveAction(() => {
          console.debug(`New active action: ${toAction.getClip().name}`);
          return toAction;
        });

        if (props.onAnimationStarted) {
          setTimeout(() => {
            if (props.onAnimationStarted)
              props.onAnimationStarted(toAction.getClip().name);
          }, fadeIn);
        }
      } else {
        toAction.reset().play();
        setActiveAction(() => {
          console.debug(`New active action: ${toAction.getClip().name}`);
          return toAction;
        });
      }
    },
    [activeAction, props, setActiveAction],
  );

  const launchAnimation = useCallback(
    (element: T['name'], parameters: AnimationParams<T> = {}) => {
      const {
        repeatMode = LoopAnimation,
        fadeMode = DefaultFadeAnimation,
        duration = -1,
        clampWhenFinished = true,
        onFinished,
      } = parameters;

      const currentAnimation = actions[element];
      if (!currentAnimation) return;

      currentAnimation.clampWhenFinished = clampWhenFinished;
      currentAnimation.setLoop(repeatMode.mode, repeatMode.repeats);
      if (duration > 0) {
        currentAnimation.setDuration(duration);
      }

      if (onFinished !== undefined) {
        setOnFinishedCallback((old) => [
          ...old,
          {
            target: element,
            uses: 1,
            ...onFinished,
          },
        ]);
      }

      fadeAction(
        currentAnimation,
        fadeMode.fadeInDuration,
        fadeMode.fadeOutDuration,
        fadeMode.isReset,
      );
    },
    [actions, fadeAction],
  );

  const onAnimationFinished = useCallback(
    (e: AnimationFinished) => {
      const animationName = e.action?.getClip().name;
      if (!animationName) return;

      onFinishCallbacks
        .filter((x) => x.target === animationName)
        .forEach((x) => {
          if (x.callback !== undefined) x.callback();

          if (x.uses) x.uses--;

          if (x.launchBaseAnimation && props.baseAnimation) {
            launchAnimation(props.baseAnimation, x.launchBaseAnimation);
          }
        });

      setOnFinishedCallback((old) => {
        return old.filter((x) => x.uses && x.uses <= 0);
      });

      if (props.onAnimationFinished) props.onAnimationFinished(animationName);
    },
    [launchAnimation, onFinishCallbacks, props],
  );

  useEffect(() => {
    mixer.addEventListener('finished', onAnimationFinished);

    return () => mixer.removeEventListener('finished', onAnimationFinished);
  }, [mixer, onAnimationFinished]);

  useImperativeHandle(props.myRef, () => ({
    animate(element, parameters = {}) {
      launchAnimation(element, parameters);
    },
    animateRandom(elements, parameters = {}) {
      if (elements.length === 0)
        throw new Error('Must contain at least one animation');

      const anim = getRandom(elements, 1)[0];
      launchAnimation(anim, parameters);
    },
  }));

  useEffect(() => {
    if (props.baseAnimation)
      launchAnimation(props.baseAnimation, {
        fadeMode: InstantFadeAnimation,
      });

    if (props.onLoaded) props.onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default AnimationLoader;
