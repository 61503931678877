import {
  useRef,
  useImperativeHandle,
  useCallback,
  forwardRef,
  useState,
} from 'react';
import EventsMessanger, {
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';
import {
  DialogCommunicatorDimsSendingEvent,
  DialogCommunicatorSendingEvent,
  DialogCommunicatorGettingEvent,
  DialogCommunicatorScenarioGettingEvent,
  DialogCommunicatorWindowSizeEvent,
  DialogCommunicatorButtonClickSendingEvent,
} from '../../pages/scriptIntegrations/dialog/CommTypes';

interface DialogIntegrationProps {
  setBack: () => void;
  setNext: () => void;
  setFallback: () => void;
  setFullscreen: (isFullscreen: boolean) => void;

  setFinalSlide: () => void;
  setFirstSlide: () => void;

  loadScenario: (id: string) => void;
}

export interface DialogIntegrationRef {
  onHeightChanged: (height: number) => void;
  onWidthChanged: (width: number) => void;
  onButtonClick: (buttonId: string) => void;
  onStart: () => void;
  onFinish: () => void;
  onNext: () => void;
  onBack: () => void;
  onFallback: () => void;
  onClosed: () => void;
}

const DialogEventHandler = forwardRef<
  DialogIntegrationRef,
  DialogIntegrationProps
>(
  (
    {
      setBack,
      setNext,
      setFallback,
      setFinalSlide,
      setFirstSlide,
      loadScenario,
      setFullscreen,
    },
    ref,
  ) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const eventMessangerRef =
      useRef<
        EventSystemRefProps<
          | DialogCommunicatorDimsSendingEvent
          | DialogCommunicatorSendingEvent
          | DialogCommunicatorButtonClickSendingEvent
        >
      >(null);

    const incomingEventsHandler = useCallback(
      (
        message:
          | DialogCommunicatorGettingEvent
          | DialogCommunicatorScenarioGettingEvent
          | DialogCommunicatorWindowSizeEvent,
      ) => {
        switch (message.event) {
          case 'load-scenario':
            loadScenario(message.scenarioId);
            return;

          case 'set-window-size':
            setFullscreen(message.size === 'fullscreen');
            return;

          case 'back':
            setBack();
            return;

          case 'next':
            setNext();
            return;

          case 'fallback':
            setFallback();
            return;

          case 'last':
            setFinalSlide();
            return;

          case 'first':
            setFirstSlide();
            return;

          case 'checkLoaded':
            if (isLoaded) {
              eventMessangerRef.current?.sendMessage(
                {
                  event: 'onLoaded',
                },
                'dialog',
              );
            }
            return;
        }
      },
      [
        loadScenario,
        setFullscreen,
        setBack,
        setNext,
        setFallback,
        setFinalSlide,
        setFirstSlide,
        isLoaded,
      ],
    );

    useImperativeHandle(ref, () => ({
      onButtonClick(buttonId) {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'click',
            buttonId,
          },
          'dialog',
        );
      },
      onHeightChanged(height: number) {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'height-changed',
            value: height,
          },
          'dialog',
        );
      },
      onWidthChanged(width: number) {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'width-changed',
            value: width + 10,
          },
          'dialog',
        );
      },
      onStart() {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onStart',
          },
          'dialog',
        );
      },
      onFinish() {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onFinished',
          },
          'dialog',
        );
      },
      onNext() {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onNext',
          },
          'dialog',
        );
      },
      onBack() {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onBack',
          },
          'dialog',
        );
      },
      onFallback() {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onFallback',
          },
          'dialog',
        );
      },
      onClosed() {
        eventMessangerRef.current?.sendMessage(
          {
            event: 'onClosed',
          },
          'dialog',
        );
      },
    }));

    const OnLoaded = useCallback(() => {
      setIsLoaded(true);
      eventMessangerRef.current?.sendMessage(
        {
          event: 'onLoaded',
        },
        'dialog',
      );
    }, []);

    return (
      <EventsMessanger<
        | DialogCommunicatorGettingEvent
        | DialogCommunicatorScenarioGettingEvent
        | DialogCommunicatorWindowSizeEvent,
        | DialogCommunicatorDimsSendingEvent
        | DialogCommunicatorSendingEvent
        | DialogCommunicatorButtonClickSendingEvent
      >
        componentName="dialog"
        myRef={eventMessangerRef}
        onMessage={incomingEventsHandler}
        onLoaded={OnLoaded}
      />
    );
  },
);

export default DialogEventHandler;
