import * as THREE from 'three';
import {AnimationRepeatData} from './AnimationLoader';

export const RepeatOnceAnimation: AnimationRepeatData = {
  mode: THREE.LoopOnce,
  repeats: 1,
};

export const LoopAnimation: AnimationRepeatData = {
  mode: THREE.LoopRepeat,
  repeats: Infinity,
};
