import { parseNotification } from 'src/utils/helpers';
import { validateTransition, validators } from 'src/utils/validators';
class Store {
    constructor() {
        this.incrementCounter = () => (this.counter += 1);
        this.getCounter = () => this.counter;
        this.counter = 0;
        this.add = null;
    }
    addNotification(notification) {
        if (process.env.NODE_ENV === 'development') {
            const transitions = ['slidingEnter', 'slidingExit', 'touchRevert', 'touchSlidingExit'];
            transitions.forEach((transition) => validateTransition(notification, transition));
            validators.forEach((validator) => validator(notification, this.types));
        }
        this.incrementCounter();
        const parsedNotification = parseNotification(notification, this.types, this.defaultNotificationWidth);
        return this.add(parsedNotification);
    }
    register(parameters) {
        const { addNotification, removeNotification, removeAllNotifications, types, defaultNotificationWidth } = parameters;
        this.add = addNotification;
        this.removeNotification = removeNotification;
        this.removeAllNotifications = removeAllNotifications;
        this.defaultNotificationWidth = defaultNotificationWidth;
        this.types = types;
    }
}
export default new Store();
