import {
  ButtonStyleCoffee,
  ButtonStyleSimple,
} from '../../../../components/form/button/SimpleButton';
import {DialogWindowSize} from '../../../../components/ui/dialog/types';
import {DialogDTO} from '../dto/DialogDTO';

export const DialogDatabase: {[id: string]: DialogDTO} = {
  empty: {
    id: 'empty',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '0BA3CAE6-4091-4B14-BB73-A97C72E8D791',
      },
    ],
  },
  //
  coffee1: {
    id: '866DD0DB-4E3D-437F-9C1C-1495772A5FA6',
    prefixId: '512coffee',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '87A2DE47-8829-451D-917E-A71F4F5B933C',
        // name: '512 Coffee',
        text: "Hey! I'll help you to pick the coffee that fits your preferences",
        buttons: [
          {
            id: '56BC0B70-C857-4917-B7FA-FD01505F978A',
            title: 'Let’s go!',
            isNextOnClick: true,
          },
          {
            id: '1FB8539B-758D-4A17-A9CE-4E27C115E4D1',
            title: 'Close',
            dexButtonStyle: ButtonStyleCoffee.Blank,
          },
        ],
      },
      {
        id: '57DC450B-8967-456A-BC44-92A0DBE301FA',
        text: 'Are you OK with caffeine?',
        buttons: [
          {
            id: 'C7D11CAA-61A1-4176-8EAB-37E26CE920AB',
            title: 'Yes!',
            isNextOnClick: true,
          },
          {
            id: 'A085874F-7EF3-4A39-881E-EED0085A78E3',
            title: 'Decaf',
          },
        ],
      },
    ],
  },
  coffe1_select1: {
    id: 'F5831BB7-67D8-48D8-9B39-7C4DCFF060C0',
    prefixId: '512coffee',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '20548C38-F4C4-43C3-8FDF-704599646A43',
        text: 'Decaf coffee never Try it now!d this good! Dark chocolate, warm cinnamon, and creamy vanilla, topped off with the mouth-watering scent of freshly baked shortbread',
        buttons: [
          {
            id: '0DBC0628-4F63-4C9D-A05C-3FE229605B9F',
            title: 'Try it now!',
          },
          {
            id: '48B9DC95-33CB-4BA3-B17E-385BC8D006B4',
            title: 'Show me other options',
            dexButtonStyle: ButtonStyleCoffee.Blank,
            isNextOnClick: true,
          },
        ],
      },
      {
        id: '91CCF91B-D2E6-4D2A-9880-2936B615733C',
        text: "Oh no, looks like we've run out of decaf coffee for now",
        buttons: [
          {
            id: '3D99FD9C-C027-4D41-A548-3E7DF8D111C8',
            title: 'Next',
            isNextOnClick: true,
          },
        ],
      },
      {
        id: '7442B66B-7FE0-4EF0-A7FF-B644ACA88E57',
        text: "But don't worry, we have plenty of other delicious options to choose from!",
        buttons: [
          {
            id: 'E5E5922A-CF4F-47B2-B209-3402641C7ECC',
            title: 'Next',
            isNextOnClick: true,
          },
        ],
      },
      {
        id: 'ABE0D50A-EDEB-4A13-8474-72FA1D5C7BC3',
        text: 'Let me show you what else we have in stock',
        buttons: [
          {
            id: '371EA88F-C73D-4B4A-B38F-81548095242A',
            title: 'Lets go',
            isFinishOnClick: true,
          },
        ],
      },
    ],
  },
  coffe1_question2: {
    id: '49EFD283-219A-4B6F-B867-15423460AEB6',
    prefixId: '512coffee',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: 'DC6809FF-4C6B-485D-8BD6-01799FFFA745',
        text: "You'll love our blends that feature delicious hints of berries! Are you a fan of fruity notes?",
        buttons: [
          {
            id: '81B8102B-2B20-4843-AA3F-C2B6A983D808',
            title: 'Yes',
          },
          {
            id: '8D716890-07A3-42BF-BCE2-74648D1E2008',
            title: 'No',
            isNextOnClick: true,
          },
        ],
      },
    ],
  },
  coffe1_select2: {
    id: '8134CF06-467F-453D-871A-65FC9C215D8F',
    prefixId: '512coffee',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '7098102D-2D6A-4289-8C74-135176690846',
        text: 'Experience the perfect harmony of natural honey, freshly-picked raspberries, and delicate floral notes in Ehtiopian coffee from Dumerso',
        buttons: [
          {
            id: 'B9FBF4E3-D702-4772-8DB7-0DEF7280BBF7',
            title: 'Try it now!',
          },
          {
            id: '465C81A2-AFE7-4490-ABC2-AF9F017E608C',
            title: 'Show me other options',
            isNextOnClick: true,
            dexButtonStyle: ButtonStyleCoffee.Blank,
          },
        ],
      },
      {
        id: 'EDD83FE6-9C2A-467A-B175-8ED781824F6D',
        text: 'Experience the perfect harmony of blueberry, milk chocolate and juicy notes in unique Ehtiopian coffee from Bekele Belachew',
        buttons: [
          {
            id: '207A5BAC-EE0C-4BF8-B551-6DE4D1ABBB23',
            title: 'Try it now!',
          },
          {
            id: '90F8C76D-9FE0-48CE-960E-6684990758C5',
            title: 'Show me other options',
            isNextOnClick: true,
            dexButtonStyle: ButtonStyleCoffee.Blank,
          },
        ],
      },
    ],
  },
  coffe1_question3: {
    id: 'C97D382E-E52B-43E5-B7C1-0AC25444CE25',
    prefixId: '512coffee',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '64B8A168-D3E4-4D84-8DE2-C3639C7963E3',
        text: 'Try our smooth and luxurious blend that will have your espresso machine singing with delight',
        buttons: [
          {
            id: '026FD6DD-3B59-4115-9B7B-2BF8D6B7FE39',
            title: 'Try it now!',
          },
          {
            id: '095F11CA-6C18-4F33-86F1-B523B53CD6E0',
            title: 'Show me other options',
            isNextOnClick: true,
            dexButtonStyle: ButtonStyleCoffee.Blank,
          },
        ],
      },
      {
        id: 'A9F6CDAB-4499-4032-A3A1-0D62AEA4FB1B',
        text: "Even if there's a flavor you're not too fond of, the unique combination of our blends is guaranteed to change your mind",
        buttons: [
          {
            id: '97DE7588-5711-44A9-9B7D-544FCC81FC53',
            title: 'Got it!',
          },
        ],
      },
    ],
  },
  //
  intro: {
    id: 'intro',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '32DDA7C2-280A-406D-AFED-BAFD646C8C00',
        name: 'Dexilon',
        text: 'Hey! Welcome to Dexilon, let’s open your first order.',
        buttons: [{id: 'initial', title: 'Start', isNextOnClick: true}],
      },
      {
        id: '60EA0743-591F-4A9E-82B6-7A410FB0A36F',
        name: 'Dexilon',
        text: 'We’ll go through 4 stages of training. Click the TestNet button',
        buttons: [{id: 'initial', title: 'Start', isNextOnClick: true}],
      },
    ],
  },
  start: {
    id: 'start',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '472548C4-F862-483B-9088-3C0DE1C50336',
        name: 'Welcome to Dexilon',
        text: 'Hey! I am Mr. Dexilon',
        buttons: [
          {
            id: 'C3DCC2B7-B3AA-41E5-A099-A98A1BE78F37',
            title: 'Start Tutorial',
            isFinishOnClick: true,
          },
          {
            id: '9E862AF5-83A7-4036-B55D-7F237AB3000C',
            title: 'Skip Tutorial',
            dexButtonStyle: ButtonStyleSimple.Blank,
            isNextOnClick: true,
          },
        ],
      },
      {
        id: 'DD768D98-0AE3-4747-99B3-D13F10117165',
        name: 'Dexilon',
        text: 'Hey! I am Mr. Dexilon',
        buttons: [
          {
            id: 'FD4267F6-B36A-4D33-8FCA-EBF95A3298AE',
            title: 'Start Tutorial',
            isNextOnClick: true,
          },
        ],
      },
    ],
  },
  firstTask: {
    id: 'firstTask',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '7AB765C7-0346-4BEF-B960-7B7EDEF79FE6',
        name: 'Dexilon',
        text: 'Click on "Connect Wallet" button to connect your Metamask account',
      },
      {
        id: '2BD070DA-BFE1-47D5-8048-C7128BDCEF0C',
        name: 'Dexilon',
        text: 'Select a wallet to connect',
      },
    ],
  },
  firstTaskLoading: {
    id: 'firstTaskLoading',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '7AB765C7-0346-4BEF-B960-7B7EDEF79FE6',
        name: 'Dexilon',
        text: 'Confirm the authorization in MetaMask popup',
      },
    ],
  },
  secondTask: {
    id: 'secondTask',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '3C9F8AB7-4C5A-4A6A-8BB7-83B92829062E',
        name: 'Dexilon',
        text: 'BRAVO! Click on Claim USDT token to take your reward!',
        buttons: [
          {
            id: '5BE5F3DE-ADDB-4EA0-89E2-F7E2F427D88F',
            title: 'Skip',
            dexButtonStyle: ButtonStyleSimple.Blank,
            isFinishOnClick: true,
          },
        ],
      },
      {
        id: 'F4CC0560-5628-40D3-AF37-EB0C79C775DC',
        name: 'Dexilon',
        text: 'Great, add the received tokens to your wallet. Click on Metamask, then on the “Add Tokens” button',
        buttons: [
          {
            id: '609FC0B3-9F32-4B52-9F0E-890766ED88EA',
            title: 'Done',
            isFinishOnClick: true,
          },
        ],
      },
    ],
  },
  thirdTask: {
    id: 'thirdTask',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: 'AEC8D7A3-F4F5-46DD-BD6A-C8916751BD47',
        name: 'Dexilon',
        text: 'Use USDT Tokens to make the first deposit! Click "Deposit" button.',
      },
    ],
  },
  thirdTaskApprove: {
    id: 'thirdTaskApprove',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: 'D0B96D4E-827E-4EC0-9FE4-0959EFE6CBDA',
        name: 'Dexilon',
        text: 'Enter the deposit amount and click approve',
      },
      {
        id: '0CD1834C-23E1-4914-B4AF-16EC8F7760BD',
        name: 'Dexilon',
        text: 'Click "confirm" to approve the transaction in MetaMask',
      },
    ],
  },
  thirdTaskDeposit: {
    id: 'thirdTaskDeposit',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '48404923-2FE0-4409-9CA5-C9954F0D9B2F',
        name: 'Dexilon',
        text: 'Now confirm the deposit',
      },
      {
        id: 'FDDA1CB5-3074-4B03-AB3A-1ED288D50AE7',
        name: 'Dexilon',
        text: 'Pro tip: to make faster deposit use higher gas fee (i.e. GWEI 30) in MetaMask',
        buttons: [
          {
            id: '92E25796-7546-4EAB-AAD8-E11E92C6CA50',
            title: 'Next',
            isNextOnClick: true,
          },
        ],
      },
      {
        id: '897CCF3A-7BD5-42B9-A8A9-39BDD1907EAC',
        name: 'Dexilon',
        text: 'Click "Confirm" in Metamask',
      },
      {
        id: '927DD6A0-4362-4CD5-88D3-4F6D72233AE4',
        name: 'Dexilon',
        text: 'Wait until the transaction is completed',
      },
    ],
  },
  thirdTaskSuccess: {
    id: 'thirdTaskSuccess',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '7AAC8F9E-D765-4AD6-9953-AA3DA146DA78',
        name: 'Dexilon',
        text: 'Nice work!',
      },
    ],
  },

  futuresExplain: {
    id: 'futuresExplain',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '6DAEAC2A-582C-47AF-8C3F-1BDF757A30B9',
        name: 'Dexilon',
        text: 'Success! You have recieved a deposit directly to your futures account.',
        buttons: [
          {
            id: '7918C70C-8E11-4A85-AE6C-B9649F1D6107',
            title: 'Finish',
            isNextOnClick: true,
          },
        ],
      },
    ],
  },

  tournamentAnnouncement: {
    id: 'tournamentAnnouncement',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: 'A88E7E44-5ED5-45F5-A9EF-76CCFF5740F5',
        name: 'Join Dexilon trainidg Tournament!',
        text: 'Become the most active trader and claim more rewards',
        buttons: [
          {
            id: '62479DCF-7CFD-406D-9744-A205A7CC8945',
            title: 'Start trading',
            isNextOnClick: true,
          },
          {
            id: 'B88E0B5C-8223-434B-929B-91C6AFA123B7',
            title: 'About tournament',
            isNextOnClick: true,
          },
        ],
      },
    ],
  },

  test0: {
    id: 'test0',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: 'D1A6D6C5-278F-4D1D-815C-D9C64F298C15',
        name: 'Dexilon',
        text: 'What assets can be traded at Dexilon?',
        testButtons: [
          {
            id: 'ED84402D-12EB-4016-86B7-526C0F999FBA',
            title: 'Spot Crypto',
            isFinishOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: '610EA108-30EC-4F9F-9059-9DFFF572CCC9',
            title: 'Trading Options',
            isFinishOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: 'DA48B048-E331-4591-A983-2B832211A268',
            title: 'Perpetual Swap Contracts with funding',
            isFinishOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            isFullLine: true,
            clickDelay: 2000,
          },
          {
            id: '25CEBFBB-5EAF-4FF5-9F8A-B0985548E788',
            title: 'All of the above',
            dexButtonStyle: ButtonStyleSimple.Blank,
            isFinishOnClick: true,
            isCoorrectAnswer: true,
            isFullLine: true,
            clickDelay: 2000,
          },
        ],
      },
    ],
  },
  test1: {
    id: 'test1',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: 'D8DEE51C-39AD-4333-B294-C72D621B32DC',
        name: 'Dexilon',
        text: 'What is Maker Fee on Dexilon?',
        testButtons: [
          {
            id: '4FF4F725-156C-4E76-8A84-BAA298EB2D71',
            title: '10 BPS',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            clickDelay: 2000,
            dexButtonStyle: ButtonStyleSimple.Blank,
          },
          {
            id: '06C44557-D7F5-452F-B469-8CE573D0E289',
            title: '10 USDT',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            clickDelay: 2000,
            dexButtonStyle: ButtonStyleSimple.Blank,
          },
          {
            id: '9A7EC0CA-F674-4B9C-B981-2C5E7CA37C0E',
            title: 'Current ETH gas cost',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            clickDelay: 2000,
            dexButtonStyle: ButtonStyleSimple.Blank,
          },
          {
            id: 'D1425687-ACE1-438F-9551-A41C5D2B1587',
            title: '0.01% rebate',
            isFinishOnClick: true,
            isCoorrectAnswer: true,
            clickDelay: 2000,
            dexButtonStyle: ButtonStyleSimple.Blank,
          },
        ],
      },
      {
        id: 'F073F051-C325-4659-9F2B-B556D741FC28',
        name: 'Dexilon',
        text: 'Every limit order has rebate – means every time your limit order is filled, you are getting paid 0.01% of the trade size.',
        buttons: [
          {
            id: '0E73E4D2-0CCF-4EFC-934D-ADC83DDA9706',
            title: 'Next',
            isFinishOnClick: true,
          },
        ],
      },
    ],
  },
  test2: {
    id: 'test2',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '1DE695F9-0800-4673-8F22-6AB9DA9E7F1B',
        name: 'Dexilon',
        text: 'What is Dexilon Chain?',
        testButtons: [
          {
            id: 'E6C2908C-70C2-4389-B6EA-2891011D10EB',
            title: 'Ethereum Fork',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: '30360753-2125-4ADB-AF60-F0E6A1DF8A16',
            title: 'Subnet of Avalanche ',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: '8CA3852F-266A-406E-BD93-580703A3A23F',
            title: 'Sidechain build with Cosmos',
            isFinishOnClick: true,
            isCoorrectAnswer: true,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: '7BBCC274-3567-4B3C-9A55-79A7C1B1EBFC',
            title: 'Bitcoin Fork',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
        ],
      },
    ],
  },
  test3: {
    id: 'test3',
    prefixId: 'dexilon',
    windowSize: DialogWindowSize.Auto,
    elements: [
      {
        id: '6B3E7F82-03D0-4C3A-B5B4-F0B79F05F8E7',
        name: 'Dexilon',
        text: 'How much % Dexilon referral program pays?',
        testButtons: [
          {
            id: 'FA1B43A8-09C2-4ACA-9973-46CFC857191E',
            title: '40%',
            isFinishOnClick: true,
            isCoorrectAnswer: true,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: '6A7EED1B-C897-45DC-8FA7-36BBDFB154A5',
            title: '0%',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: '52EEF7FE-45E5-49C8-830E-26B6E1B95013',
            title: '100%',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
          {
            id: 'A0E1D443-6029-46DF-B6E4-C7962AE15840',
            title: '10%',
            isNextOnClick: true,
            isCoorrectAnswer: false,
            dexButtonStyle: ButtonStyleSimple.Blank,
            clickDelay: 2000,
          },
        ],
      },
      {
        id: 'C4D50F54-9F9D-4B79-AA10-408380A0E541',
        name: 'Dexilon',
        text: 'Users can earn great money by bringing friends to Dexilon and receive 20% of fees that their referrals pay. In addition 20% of taker fees generated will be paid back to low fee tier users. All in USDC.',
        buttons: [
          {
            id: 'D5BBBDD4-B1CF-49DB-81AD-802F27E3D4C4',
            title: 'Next',
            isFinishOnClick: true,
          },
        ],
      },
    ],
  },
};
