import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {UseShortMutationOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../../services/globalNotification/errorHandler';
import APIRequest from '../../Api';
import {ApiError} from '../../basicTypes/ApiError';
import {AnonymousGameSaveDAO} from './dao/AnonymousGameSaveDAO';
import {IntegrationSaveDAO} from './dao/IntegrationSaveDAO';
import {IntegrationGetSaveDAO} from './dao/SaveRequestDAO';
import {AnonymousGameSaveDTO} from './dto/AnonymousGameSaveDTO';

const SaveGameKey = 'integration/save/save';

export const useLoadGame = (
  options?: UseShortMutationOptions<
    string,
    AxiosError<ApiError>,
    IntegrationGetSaveDAO
  >,
) => {
  return useMutation<string, AxiosError<ApiError>, IntegrationGetSaveDAO>(
    [SaveGameKey],
    async (request: IntegrationGetSaveDAO): Promise<string> => {
      const {data} = await APIRequest.post<string>(
        '/Integration/GetSave',
        request,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      retry: 0,
      ...options,
    },
  );
};

export const useSaveGame = (
  options?: UseShortMutationOptions<
    IntegrationSaveDAO,
    AxiosError<ApiError>,
    IntegrationSaveDAO
  >,
) => {
  return useMutation<
    IntegrationSaveDAO,
    AxiosError<ApiError>,
    IntegrationSaveDAO
  >(
    [SaveGameKey],
    async (newSimulation: IntegrationSaveDAO): Promise<IntegrationSaveDAO> => {
      await APIRequest.post(`/Integration/Save`, newSimulation);
      return newSimulation;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useAnonymousLoadGame = (
  options?: UseShortMutationOptions<
    AnonymousGameSaveDTO,
    AxiosError<ApiError>,
    string
  >,
) => {
  return useMutation<AnonymousGameSaveDTO, AxiosError<ApiError>, string>(
    [SaveGameKey],
    async (platformId: string): Promise<AnonymousGameSaveDTO> => {
      const {data} = await APIRequest.get<AnonymousGameSaveDTO>(
        '/Save/GetAnonymousSave',
        {
          params: {
            platformId,
          },
        },
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      retry: 0,
      ...options,
    },
  );
};

export const useAnonymousSaveGame = (
  options?: UseShortMutationOptions<
    void,
    AxiosError<ApiError>,
    AnonymousGameSaveDAO
  >,
) => {
  return useMutation<void, AxiosError<ApiError>, AnonymousGameSaveDAO>(
    [SaveGameKey],
    async (request: AnonymousGameSaveDAO): Promise<void> => {
      await APIRequest.post('/Save/UpdateAnonymousSave', request);
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      retry: 0,
      ...options,
    },
  );
};
